import React from 'react';
import { useSelector } from 'react-redux';
import useHeader from '../../../../hooks/useHeader';
import AddTransactionForm from '../../../../components/Form/AddTransactionForm';
import { Column, Container, Main, Row } from '../../../../components/Layout';
import AddMultiplePhoto from '../../../../components/Common/AddMultiplePhoto';
import { RootState } from '../../../../reducers';

import './AddTransaction.scss';

export type RealizationImages = {
  previewUrl: string;
  file: File;
};

const AddTransaction = () => {
  useHeader('Dodaj realizacje', '/dashboard');

  const images = useSelector((state: RootState) => state.multiplePhoto);

  return (
    <Main>
      <Container className="add-transition">
        <Row>
          <AddMultiplePhoto />
        </Row>
        <Row>
          <Column className="add-description-module">
            <AddTransactionForm files={images.map((image) => image.file)} />
          </Column>
        </Row>
      </Container>
    </Main>
  );
};

export default AddTransaction;
