import React from 'react';
import { Container, Main } from '../../../components/Layout';
import useHeader from '../../../hooks/useHeader';
import PageTitle from '../../../components/Common/PageTitle';
import { SectionTitle } from '../../../components/Common';
import { sanitize } from '../../../utils/sanitize';

import './DHTHotChallenge.scss';

const action = {
  id: 3,
  title: 'DHT Hot Challenge',
  image: 'hot_challenge_dlugi.png',
  description:
    'Myśl pozytywnie, działaj aktywnie i wygrywaj. Staw czoła czterem osobnym wyzwaniom i zgarnij reprezentacyjny budżet. Wyjątkowe i zaskakujące wyzwania dla DHT! Do samego końca pozostają tajemnicą, a opublikowanie zasad ich działania następuje dopiero w momencie startu. To niestandardowy układ 4 konkursów, w których DHT będzie musiał wykazać się zaangażowaniem, umiejętnościami komunikacyjnymi i kreatywnością – angażując swoich Dekarzy. Co 2 miesiące nowe wyzwanie!',
};

const DHTHotChallenge = (): JSX.Element => {
  useHeader('', '/dashboard/');

  return (
    <Main>
      <header className="dht-action-header">
        <img src={`/assets/images/${action!.image}`} alt={action!.title} />
      </header>
      <Container>
        <div className="dht-action-content">
          <PageTitle>{action!.title}</PageTitle>
          <p dangerouslySetInnerHTML={{ __html: sanitize(action!.description) }} />
          <SectionTitle>HOT CHALLENGE ONE</SectionTitle>
          <p>Ta aktywność zdecydowanie popchnie Was w kierunku budowania relacji z Dekarzami.</p>
          <p>
            Waszym zadaniem będzie zrobienie sobie z uczestnikiem konkursu TOP DEKARZE CREATON{' '}
            <strong>wspólnego zdjęcia-selfie.</strong>
          </p>
          <p>Wstawiacie zdjęcie wykorzystując do tego platformę Top Dekarze - guzik DHT HOT CHALLENGE.</p>
          <img src="/assets/images/dht_hot_one.jpg" alt="DHT Hot Challenge" />
          <ol>
            <li>Zróbcie sobie wspólne zdjęcie</li>
            <li>Wysyłaj zdjęcia za pośrednictwem platformy (od 1.09., do 7.09 mailowo)</li>
            <li>Każde spotkanie i zdjęcie z dekarzem – uczestnikiem programu to 1 pkt.</li>
            <li>Punkty będą wliczone do rankingu w ramach DHT HOT Challenge, w walce o zdobycie ekstra budżetu.</li>
          </ol>
          <p>Pamiętajcie, że walczycie o wysokie wygrane!</p>
        </div>
      </Container>
    </Main>
  );
};

export default DHTHotChallenge;
