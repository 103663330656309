import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserRole } from 'topdekarze-types';
import { RootState } from '../../../reducers';
import useHeader from '../../../hooks/useHeader';
import { Column, Container, Main, Row } from '../../../components/Layout';
import MyProfileForm from '../../../components/Form/MyProfileForm';
import MyActionsForm from '../../../components/Form/MyActionsForm';
import { getUserDetails } from '../../../actions/user';
import MySuperuser from '../../../components/Common/MySuperuser';

const MyProfile = () => {
  useHeader('Profil', '/dashboard');

  const user = useSelector((state: RootState) => state.user.details);
  const dispatch = useDispatch();

  if (!user) dispatch(getUserDetails());

  if (user) {
    return (
      <Main>
        <Container>
          <Row>
            <Column className="my-profile-col">
              <MyProfileForm user={user} />
              {user?.role === UserRole.User && <MySuperuser />}
              {user?.role === UserRole.User && <MyActionsForm user={user} />}
            </Column>
          </Row>
        </Container>
      </Main>
    );
  }
  return null;
};

export default MyProfile;
