import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { QuizDetailRes } from 'topdekarze-types';
import ApiService from '../../services/api-service';

interface UseQuiz {
  quiz: any;
  startQuiz: () => any;
  endQuiz: () => any;
  quizId: string;
  isDisabled: boolean;
  checkAnswer: any;
  questionResult: any;
  runQuiz: () => void;
  isStarted: boolean;
  selected: any;
  points: any;
  quizInfo: any;
  action: string;
  quizStat: any;
}

const useQuiz = (): UseQuiz => {
  const [quiz, setQuiz] = useState();
  const [quizStat, setQuizStat] = useState();
  const [points, setPoints] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isStarted, start] = useState(false);
  const [questionResult, setQuestionResult] = useState<any>();
  const { quizId, quizStatus } = useParams();
  const [selected, setSelected] = useState<any>();
  const [quizInfo, setQuizInfo] = useState<QuizDetailRes>();
  const [action, setAction] = useState('Rozwiąz quiz');

  const startQuiz = async () => {
    await ApiService.callFetch('POST', `quiz/start-quiz/${quizId}`, (data: any) => {
      setQuiz(data);
      setIsDisabled(false);
      if (selected !== null) setSelected(null);
    });
  };
  const endQuiz = async () => {
    await ApiService.callFetch('POST', `quiz/finish-quiz/${quizId}`, (data: any) => {
      setPoints(data);
    });
  };

  const getQuizInfo = async () => {
    await ApiService.callFetch('GET', `quiz/quiz-info/${quizId}`, (data: QuizDetailRes) => {
      setQuizInfo(data);
      setQuizStat(quizStatus);
      if (quizStatus === 'finished') setAction('Pokaż wynik');
      if (quizStatus === 'on-running') setAction('Kontynuuj quiz');
      if (quizStatus === 'start') setAction('Rozpocznij quiz');
    });
  };

  const checkAnswer = async (data: any) => {
    await ApiService.callFetch(
      'POST',
      'quiz/check-answer/',
      (response: any) => {
        setQuestionResult(response.correctAnswer.id);
        setIsDisabled(true);
        setSelected(data.answerId);
      },
      null,
      data,
    );
  };

  const runQuiz = () => {
    start(true);
    startQuiz();
  };

  useEffect(() => {
    getQuizInfo();
  }, []);

  return {
    quiz,
    startQuiz,
    endQuiz,
    quizId,
    selected,
    isDisabled,
    points,
    checkAnswer,
    questionResult,
    runQuiz,
    isStarted,
    quizInfo,
    action,
    quizStat,
  };
};

export default useQuiz;
