import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from 'topdekarze-types';
import { RootState } from '../../../reducers';
import useHeader from '../../../hooks/useHeader';
import { Main } from '../../../components/Layout';
import DashboardBackground from '../../../components/Common/DashboardBackground';
import EditOption from '../../../components/Common/EditOption';
import ActionBanner from '../../../components/Common/ActionBanner';
import UserActions from '../../../components/Common/UserActions';
import EntrySection from './EntrySection';
import BannersCarousel from '../../../components/Common/BannersCarousel';
import HomeNews from './HomeNews';
import SpecialActionsGallery from './SpecialActionsGallery';

import './Start.scss';

const Start: FC = () => {
  useHeader('');
  const user = useSelector((state: RootState) => state.user.details);

  return (
    <Main className="start-view">
      <DashboardBackground />
      <EditOption className="edit-option" />
      <EntrySection />
      <HomeNews />
      <UserActions />
      <SpecialActionsGallery />
      <BannersCarousel />
      {user?.role === UserRole.User && (
        <ActionBanner path="dashboard/invite-user" image="baner_polecenia.png" fullSize />
      )}
    </Main>
  );
};

export default Start;
