import React from 'react';
import './Image.scss';
import clxs from 'clsx';
import clsx from 'clsx';

interface Props {
  image?: string;
  absolute?: boolean;
  className?: string;
  round?: boolean;
}

const Image = ({ image, className, round, absolute }: Props) => (
  <img
    className={clsx('image', {
      round,
      [`${className}`]: className,
    })}
    src={absolute ? image : `/assets/images/${image}`}
  />
);

export default Image;
