import React from 'react';
import { AcceptStatus, TransactionTableRes } from 'topdekarze-types';
import { FieldType, transactionTableSchema } from 'topdekarze-tables';
import useHeader from '../../../../hooks/useHeader';
import { Main } from '../../../../components/Layout';
import { Button } from '../../../../components/Common';
import { history } from '../../../../App';
import ApiTable from '../../../../components/Common/ApiTable';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import TableWrapper from '../../../../components/Common/TableWrapper';

const AllTransactions = () => {
  useHeader('Realizacje', '/dashboard');

  const schema = new InteractiveTableSchema(transactionTableSchema).removeFields('id').addFieldAtTheEnd({
    name: 'Akcje',
    field: 'actions',
    type: FieldType.Custom,
    customRender: (value, field, row: TransactionTableRes) => {
      if (row.acceptStatus === AcceptStatus.Unaccepted) {
        return (
          <Button
            primary
            small
            text="Weryfikuj"
            click={(id: string) => history.push(`/dashboard/transactions/list/${row.id}`)}
          />
        );
      }
    },
    mobile: true,
  });

  return (
    <Main>
      <TableWrapper>
        <ApiTable scheme={schema} apiEndpointSubUrl="transaction/my-list" />
      </TableWrapper>
    </Main>
  );
};

export default AllTransactions;
