import { ItfApiForm } from 'itf_formbuilder_react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthLoginRequest, AuthLoginResult } from 'topdekarze-types';
import { setUser } from '../../../../actions/auth';
import { successNotification } from '../../../../actions/notifications';
import { history } from '../../../../App';
import { Spinner, Button } from '../../../../components/Common';
import { ButtonsContainer, Container, FormContainer, Main } from '../../../../components/Layout';
import { __ } from '../../../../helpers/i18n';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';

const UsersPreview = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  return (
    <Main>
      <Container>
        <FormContainer>
          <ItfApiForm
            formId="previewUser"
            schemaMayBeDynamic
            schemaCacheEnabled={false}
            loadingInfo={<Spinner light overlay />}
            schemaFetcher={() => ApiService.loadForm(`user/form/get-user-data/${userId}`)}
            sendFetcher={(formId: string, values: any) => ApiService.sendForm('#', values)}
            submitButton={<p />}
            onTranslateString={formOnTranslateString}
            onSavedSuccessfully={(data: AuthLoginResult) => {
              dispatch(successNotification('Edycja poprawka'));
              history.push('/dashboard/users');
            }}
            onRenderFullField={(field) => {
              switch (field.id) {
                case 'companyFile':
                  if (field.value) {
                    return (
                      <ButtonsContainer>
                        <Button
                          key={field.id}
                          download
                          blank
                          to={`${ApiService.url}user/get-company-file/${userId}`}
                          text="Pobierz logotyp"
                        />
                      </ButtonsContainer>
                    );
                  }
                  return <p key={field.id} />;
                default:
                  return null;
              }
            }}
          />
        </FormContainer>
      </Container>
    </Main>
  );
};

export default UsersPreview;
