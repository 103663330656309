import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { AuthLoginResult, UserRegisterReq } from 'topdekarze-types';
import FormContainer from '../../Layout/FormContainer';
import { Spinner, Button } from '../../Common';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';
import { history } from '../../../App';
import { __ } from '../../../helpers/i18n';
import { formOnTranslateString } from '../../../utils/trans-form';

import './RegisterForm.scss';
import { ButtonsContainer } from '../../Layout';
import { useDispatch } from 'react-redux';

const RegisterForm = () => {
  const dispatch = useDispatch();
  return (
    <FormContainer grid>
      <ItfApiForm
        formId="registerForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('user/form/register')}
        sendFetcher={(formId: string, values: UserRegisterReq) => ApiService.sendForm('user/register', values)}
        submitButton={
          <ButtonsContainer center>
            <Button primary type="submit" text={__('application.register')} />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
        onSavedSuccessfully={(data: AuthLoginResult) => {
          dispatch(successNotification(`${__('application.registerCorrect')}`));
          history.push('/login');
        }}
      />
    </FormContainer>
  );
};

export default RegisterForm;
