import React from 'react';
import { NavLink } from 'react-router-dom';
import { Column } from '../../Layout';
import Icon from '../Icon';
import './NavItem.scss';

interface Props {
  iconName: string;
  path: string;
  className?: string;
}

const NavItem = ({ iconName, path, className }: Props) => (
  <Column autoWidth as="li" className={className}>
    <NavLink exact to={path}>
      <Icon className="nav-icon" icon={iconName} />
    </NavLink>
  </Column>
);

export default NavItem;
