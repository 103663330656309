import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '../../components/Common';
import SectionTitle from '../../components/Common/SectionTitle';
import { Container, Main, ButtonsContainer } from '../../components/Layout';
import useHeader from '../../hooks/useHeader';
import { RootState } from '../../reducers';
import ApiService from '../../services/api-service';
import { getUserDetails } from '../../actions/user';
import { history } from '../../App';
// import './CompetitionRules.scss';

const Rules = () => {
  useHeader('Regulamin programu motywacyjnego', '/');
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.details);
  const accepted = user?.rulesAccepted;

  const accept = async () => {
    await ApiService.callFetch('PATCH', 'user/accept-rules', () => {
      dispatch(getUserDetails());
    });
  };

  useEffect(() => {
    if (user && accepted !== user.rulesAccepted) {
      history.push('/');
    }
  }, [user]);

  return (
    <Main className="competition-rules">
      <Container>
        <strong>REGULAMIN PROGRAMU MOTYWACYJNEGO „TOP DEKARZ - CREATON POLSKA”</strong>
        <SectionTitle>§1. POSTANOWIENIA OGÓLNE</SectionTitle>
        <ol>
          <li>
            Niniejszy regulamin stanowi podstawę działania programu motywacyjnego i określa prawa i obowiązki Uczestnika
            programu, a także obowiązki Organizatora (dalej Regulamin).
          </li>
          <li>
            Organizatorem programu jest CREATON Polska spółka z ograniczoną odpowiedzialnością z siedzibą w 32-300
            Olkusz, przy ulicy Wspólnej 6, wpisaną do rejestru handlowego prowadzonego przez Sąd Rejonowy dla Krakowa –
            Śródmieścia w Krakowie XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000114321, NIP:
            8522097221, REGON: 811145245, BDO nr 000003656.
          </li>
          <li>
            Program motywacyjny skierowany jest do DEKARZY wykorzystujących w swej pracy zawodowej produkty marki
            CREATON.
          </li>
          <li>Udział w Programie jest dobrowolny.</li>
          <li>
            W ramach Programu Uczestnik za aktywności w Programie gromadzi punkty na swoim koncie rywalizując o miejsce
            w TOP 30 i zdobycie nagrody.
          </li>
          <li>Organizator jest przyrzekającym nagrodę w rozumieniu art. 919 i 921 kodeksu cywilnego.</li>
          <li>
            Prawo do korzystania z konta w Programie, a w szczególności do odbioru nagrody, jest osobiste i przysługuje
            wyłącznie Uczestnikowi.
          </li>
          <li>Program jest prowadzony na terenie Rzeczypospolitej Polskiej i trwa od 01.09.2020 r. – 31.05.2021 r.</li>
        </ol>
        <SectionTitle>§2. DEFINICJE</SectionTitle>
        <ol>
          <li>
            Program motywacyjny (lub Program) – program, prowadzony za pośrednictwem platformy internetowej
            umiejscowionej na stronie www.topdekarz.pl, pozwalający Uczestnikom na gromadzenie punktów na swoim koncie
            stworzonym na w.w. platformie zdobytych poprzez uczestnictwo w organizowanych na platformie konkursach oraz
            dający możliwość zdobycia nagrody zgodnie z regułami określonymi w Regulaminie.
          </li>
          <li>
            Platforma konkursowa (lub Platforma) – strona internetowa przeznaczona dla Uczestników Programu za
            pośrednictwem której odbywają się wszystkie aktywności w ramach Programu (adres internetowy strony:
            <a href="www.topdekarze.pl">www.topdekarze.pl</a>).
          </li>
          <li>
            Dekarz – osoba, która w profesjonalny sposób wykonuje dachy i którą Organizator zaprosił do uczestnictwa w
            Programie po weryfikacji, jako podmiot korzystający z produktów CREATON w swojej pracy zawodowej lub
            deklarujący takie korzystanie w czasie trwania Programu.
          </li>
          <li>
            Organizator – CREATON Polska spółka z ograniczoną odpowiedzialnością z siedzibą w 32-300 Olkusz, przy ulicy
            Wspólnej 6, wpisaną do rejestru handlowego prowadzonego przez Sąd Rejonowy dla Krakowa – Śródmieścia w
            Krakowie XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000114321, NIP: 8522097221,
            REGON: 811145245, BDO nr 000003656.
          </li>
          <li>
            Operator – firma WOO AGENCY SP. Z O.O. będąca operatorem platformy, która na podstawie umowy z Organizatorem
            wykonuje niektóre działania związane z prowadzeniem programu lojalnościowego.
          </li>
          <li>Punkty - punkty przyznawane na zasadach określonych w niniejszym Regulaminie.</li>
          <li>
            Formularz zgłoszeniowy - formularz znajdujący się na stronie www.topdekarze.pl, za pośrednictwem, którego
            Dekarz wyraża chęć uczestnictwa w Programie poprzez wysłanie tego formularza z prawidłowo uzupełnionymi
            swoimi danymi oraz wyraża zgodę na uczestnictwo w Programie w oparciu o zasady Regulaminu i zgodę na
            przetwarzanie jego danych osobowych.
          </li>
          <li>
            Uczestnik - Dekarz, który został zaproszony do uczestnictwa w Programie przez Organizatora za pomocą linka
            zapraszającego do Platformy, uzupełni lub zweryfikuje wcześniej uzupełnione swoje dane oraz zarejestruje
            konto na Platformie.
          </li>
          <li>
            Konkurs główny – konkurs trwający od 01.09.2020 r – 31.05.2021 r., w ramach którego za aktywności na
            platformie internetowej oraz za wykonanie prac dekarskich z użyciem określonych materiałów marki
            Organizatora, na koncie każdego Uczestnika Konkursu naliczane będą punkty.
          </li>
          <li>
            Akcje specjalne – dodatkowe akcje czasowe udostępniane w ramach Programu w trakcie trwania Konkursu
            głównego, określone specjalnymi warunkami punktowania z możliwością zdobycia osobnych nagród rzeczowych
            i/lub dodatkowych punktów.
          </li>
          <li>
            Konto – profil Uczestnika, z niezbędnymi do udziału w Programie danymi osobowymi, z informacjami na temat
            programu, z panelem wysyłania zgłoszeń, z bazą wiedzy.
          </li>
          <li>Dni robocze – dni od poniedziałku do piątku z wyjątkiem dni ustawowo wolnych.</li>
        </ol>
        <SectionTitle>§3. UCZESTNICTWO</SectionTitle>
        <ol>
          <li>
            Uczestnikiem programu może być osoba pełnoletnia posiadająca pełną zdolność do czynności prawnych oraz osoba
            prawna, jednostka organizacyjna nieposiadająca osobowości prawnej, która w sposób profesjonalny wykonuje
            dachy z użyciem dachówek Organizatora oraz spełni przesłanki uczestnictwa w Programie wskazane w
            Regulaminie.
          </li>
          <li>Każdy Uczestnik może mieć tylko jedno konto w Programie.</li>
          <li>
            Uczestnik może przystąpić do Programu wyłącznie po otrzymaniu zaproszenia, przesłanego w formie
            elektronicznej przez Organizatora na adres e-mail Uczestnika lub/i sms, wysłany na podany numer telefonu.
          </li>
          <li>
            Poprzez uruchomienie linku aktywacyjnego otrzymanego w zaproszeniu Uczestnik może rozpocząć proces
            rejestracji w Programie na Platformie Konkursowej.
          </li>
          <li>
            Proces aktywacji Konta Uczestnika wymaga udzielenia przez Uczestnika:
            <ul className="list">
              <li>Zgody na udział w Programie poprzez akceptację zasad Regulaminu.</li>
              <li>Zgody na przetwarzanie danych osobowych.</li>
            </ul>
          </li>
          <li>
            W trakcie rejestracji Uczestnik zobowiązany będzie podać lub potwierdzić: imię i nazwisko, oraz numer
            telefonu oraz nadać swoje indywidualne hasło. Z chwilą poprawnego zakończenia rejestracji Uczestnik
            przystępuje do Programu.
          </li>
          <li>
            W przypadku zmiany danych podanych przez Uczestnika w trakcie rejestracji, Uczestnik zobowiązany jest do ich
            niezwłocznego uaktualnienia w Platformie Konkursowej. W przypadku nie powiadomienia o zmianie danych
            osobowych, Organizator nie ponosi odpowiedzialności za opóźnienie lub brak działania spowodowany
            niezaktualizowaniem danych.
          </li>
          <li>
            Uczestnicy zarejestrowani w programie TOP DEKARZ, w poprzedniej edycji programu, muszą zmienić hasło przy
            pierwszym logowaniu do platformy.
          </li>
          <li>
            Dekarz, który zgłasza chęć zostanie Uczestnikiem Programu poprzez wypełnienie Formularza zgłoszeniowego
            online na stronie www.topdekarz.pl jest weryfikowany przez Organizatora pod względem spełnienia warunków
            uczestnictwa w Programie. Po pozytywnej weryfikacji Organizator wysyła zaproszenie w formie elektronicznej
            link z zaproszeniem na adres e-mail Uczestnika oraz sms, wysłanego na podany numer telefonu i następnie
            obowiązuje procedura opisana w par 3 pkt 4-7.
          </li>
        </ol>
        <SectionTitle>§4. SYSTEM PRZYZNAWANIA PUNKTÓW</SectionTitle>
        <ol>
          <li>
            Punkty przyznawane są za określone aktywności w Konkursie głównym oraz we wskazanych Akcjach specjalnych.
          </li>
          <li>
            Do Konkursu głównego Uczestnik można zgłosić realizacje dachów, jakie wykonał, z produktów CREATON
            zakupionych od 01.05.2020 r. (data dokumentu sprzedaży). Dana realizacja może być zgłoszona w danej
            kategorii produktowej tylko jeden raz przez jednego Uczestnika.
          </li>
          <li>
            Aktywności i zgłoszenia punktowane w Konkursie głównym to:
            <ol>
              <li>
                Pierwsze zalogowanie się do Programu, przez Uczestników posiadających już konto w programie TOP DEKARZ –
                CREATON Polska, w terminie między 01.09.2020 – 16.10.2020 r. oraz dokonają weryfikacji i uzupełnienia
                swoich danych na Platformie – ilość punktów 350.
              </li>
              <li>
                Regularne logowanie się do Programu, przynajmniej 3 razy w miesiącu, przez Uczestnika w okresie od
                01.09.2020 do 31.05.2021 – ilość punktów miesięcznie 150 za każdy miesiąc regularnego logowania.
              </li>
              <li>
                Zgłoszenie dachu o powierzchni minimum 100 m2, wykonanego z dachówek CREATON. Zgłoszenie musi zawierać
                wyraźne zdjęcie dachu oraz wypełniony formularz z danymi dotyczącymi zakupu i rodzaju użytej dachówki.
                Zgłoszeń można dokonywać w terminie od 15.09.2020 do 31.05.2021 – ilość punktów 500 za każdy
                zaakceptowany dach. Punkty będą przyznawane po zweryfikowaniu przez Organizatora wszystkich elementów
                zadania.
              </li>
              <li>
                Zgłoszenie dachu zrealizowanego z wykorzystaniem taśm wykończeniowych marki CREATON: CREPFORM lub
                CREAROLL. Zgłoszenie musi zawierać wyraźne zdjęcie dachu z widocznym zastosowanym produktem i wypełniony
                formularz z danymi dotyczącymi zakupu. Zgłoszeń można dokonywać w terminie od 15.09.2020 do 31.05.2021 –
                ilość punktów 500 za każdy zaakceptowany dach. Punkty będą przyznawane po zweryfikowaniu przez
                Organizatora wszystkich elementów zadania.
              </li>
              <li>
                Zgłoszenie dachu zrealizowanego z wykorzystaniem membrany marki CREATON, na dachu o wielkości minimum
                100 m. Zgłoszenie musi zawierać wyraźne zdjęcie dachu z widocznym zastosowanym produktem oraz wypełniony
                formularz z danymi dotyczącymi zakupu i rodzaju użytej membrany oraz wypełniony formularz z danymi
                dotyczącymi zakupu. Zgłoszeń można dokonywać w terminie od 15.09.2020 do 31.05.2021 – ilość punktów 500
                za każdy zaakceptowany dach. Punkty będą przyznawane po zweryfikowaniu przez Organizatora wszystkich
                elementów zadania.
              </li>
              <li>
                Prawidłowe rozwiązanie testu wiedzy znajdującego się w Programie. Każdy test jest dostępny na Platformie
                przez okres do 3 miesięcy od daty jego publikacji, ale nie dłużej niż do 31.05.2021 – 80 punktów za jego
                prawidłowe rozwiązanie.
              </li>
              <li>
                Prawidłowe rozwiązanie quizu z wiedzy dekarskiej, znajdującego się w Programie. Każdy test jest dostępny
                na Platformie przez okres do 3 miesięcy od daty jego publikacji, ale nie dłużej niż do 31.05.2021 – 80
                za jego prawidłowe rozwiązanie.
              </li>
              <li>
                Polecenie Programu innemu Dekarzowi, poprzez wysyłkę zaproszenia, bezpośrednio z Programu, w czasie
                01.09.2020 – 31.03.2021 – łączna ilość punktów do 150, przydzielanych wg poniższych zasad:
                <ul className="list">
                  <li>
                    zaproszenie wysłane przez Uczestnika do wybranego dekarza w pierwszej kolejności trafia do
                    Organizatora i podlega weryfikacji, czy zaproszony dekarz spełnia warunki uczestnictwa w Programie.
                    Po pozytywnej weryfikacji Organizator wysyła zaproszenie do dekarza, a Uczestnikowi przyznanych jest
                    75 punktów;
                  </li>
                  <li>
                    jeżeli zaproszony dekarz dokona pełnej rejestracji w Programie Uczestnikowi zapraszającemu przyznane
                    będzie kolejne 75 punktów.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Organizator zastrzega prawo do ogłaszania Akcji specjalnych, za które Uczestnicy będą mogli otrzymać
            dodatkowe punkty lub/i nagrody rzeczowe. System punktowania w Akcjach specjalnych oraz ich zasady będą
            każdorazowo publikowana na platformie konkursowej w dniu startu danej akcji.
          </li>
          <li>
            Punkty zdobyte przez Uczestnika za wykonanie zadań wskazanych powyżej w par 4 pkt 3 i 4 podlegają sumowaniu.
            Informacja o sumie punktów danego Uczestnika umieszczana jest w rankingu głównym na Platformie oraz na
            kontach Uczestników.
          </li>
          <li>
            Punkty przyznawane są od momentu akceptacji rejestracji Uczestnika w Programie, nie wcześniej niż od
            01.09.2020 r. do 31.05.2021 r.
          </li>
        </ol>
        <SectionTitle>§5. PRZYZNAWANIE NAGRÓD</SectionTitle>
        <ol>
          <li>
            Organizator w ramach Konkursu głównego nagradza określoną ilość Uczestników (30 osób), którzy w trakcie
            trwania Programu zdobędą największą ilość Punktów.
          </li>
          <li>
            Uczestnik w przypadku spełnienia przesłanek do otrzymania nagrody zostanie w terminie 14 dni, od chwili
            wystąpienia tych przesłanek, poinformowany przez Operatora Programu o wygranej.
          </li>
          <li>
            Informacja o zdobyciu nagrody, jak i również o sposobie jej realizacji zostanie przesłana drogą mailową na
            adres e-mailowy wskazany przez Uczestnika w Platformie Konkursowej.
          </li>
          <li>
            W zależności od miejsca zajętego w ostatecznym rankingu Konkursu głównego, przyznawane będą nagrody rzeczowe
            zgodnie z poniższym wykazem nagród:
            <ol>
              <li>Miejsce w rankingu 1-3 – zabudowa paki samochodu o wartości 15 tys. zł brutto.</li>
              <li>Miejsce w rankingu 4-6 – zabudowa paki samochodu o wartości 10 tys. zł brutto.</li>
              <li>Miejsce w rankingu 7-10– zabudowa paki samochodu o wartości 7 tys. zł brutto.</li>
              <li>
                Miejsca 11-20 – nagroda rzeczowa - zestaw elektronarzędzi (DeWALT COMBO, w skład zestawu wchodzi:
                Szlifierka kątowa 18V DCG405, Młotowiertarka SDS-Plus 18V DCH273, Wiertarko-Wkrętarka udarowa
                18V DCD796, Zakrętarka udarowa 18V DCF887, Akumulator Li-ion 18V 5 Ah DCB184, Ładowarka Li-ion DCB115,
                Skrzynia narzędziowa ToughSystem DS150, Skrzynia narzędziowa Tough System DS300).
              </li>
              <li>
                Miejsce 21-30 – nagroda rzeczowa - zestaw elektronarzędzi (DeWALT ZESTAW, w skład zestawu wchodzi:
                Wkrętarka udarowa 70Nm Dewalt DCD796NT, Wkrętarka udarowa 70Nm Dewalt DCD796NT, 2 akumulatory o
                pojemności 5Ah każdy, Ładowarka, 2 walizki).
              </li>
            </ol>
          </li>
          <li>
            Ze względu na charakter nagrody za miejsca 1 – 10 – zabudowa paki samochodu –specyfikacja nagrody ustalana
            będzie indywidualnie i personalizowana pod oczekiwania danego laureata nagrody w ramach budżetu określonego
            w pkt. 4. Laureaci nagrody wymienionej w pkt 4 a) – c) w celu jej odbioru będą zobowiązani do oddania
            swojego samochodu do firmy wskazanej przez Organizatora, w terminie uzgodnionym z Organizatorem, celem
            dokonania przez nią niezbędnej aranżacji i montażu nagrody, przy czym niezbędny czas do jej wykonania może
            wynieść ok. 5 Dni roboczych. Dekarz zgłaszając samochód do zamontowania nagrody głównej oświadcza, że
            wykorzystuje go do prowadzonej działalności gospodarczej.
          </li>
          <li>
            Nagrody w Akcjach specjalnych przyznawane będą w ilości oraz na podstawie warunków każdorazowo opisanych w
            Programie na platformie internetowej.
          </li>
          <li>
            Organizator zastrzega sobie prawo zmiany nagrody na inną o równorzędnej wartości, uwzględniającą interes
            Uczestnika, gdy z przyczyn od Organizatora niezależnych, przyznanie konkretnej nagrody nie będzie możliwe.
          </li>
          <li>Nagrodę, działając na zlecenie i na rzecz Organizatora wyda Uczestnikom Operator programu.</li>
          <li>Uczestnikowi nie przysługuje prawo wymiany nagrody na inną lub na ekwiwalent pieniężny.</li>
          <li>
            Uczestnik ma prawo nieprzyjęcia nagrody. Wówczas zrzeka się nagrody, która przechodzi na kolejnego
            uczestnika z listy rankingowej.
          </li>
          <li>Wydawanie nagrody następuje przesyłką kurierską lub jest ustalane indywidualnie z wygranym.</li>
          <li>
            Laureaci Konkursu, wyrażają zgodę na wykorzystanie ich wizerunku w celach marketingowych Organizatora w tym
            publikację zdjęć w prasie, mediach, Internecie. W razie konieczności na życzenie Organizatora laureat
            potwierdzi wyrażenie zgody w formie pisemnej.
          </li>
          <li>
            Laureat Konkursu głównego, jak i Akcji specjalnych, w celu odbioru nagrody, ma obowiązek podać
            Organizatorowi dodatkowe dane osobowe tj. np. numer PESEL, a także wszelkie inne dane niezbędne
            Organizatorowi do wykonania obowiązków określonych w Regulaminie lub wynikających z powszechnie
            obowiązujących przepisów prawa.
          </li>
          <li>
            Laureat Konkursu głównego, jak i Akcji specjalnych nie nabywa prawa do nagrody, jeżeli nie spełnił
            któregokolwiek z warunków określonych w niniejszym Regulaminie, w tym nie wskazał w terminie podanym przez
            Organizatora danych umożliwiających realizację prawa do nagrody oraz nie przekazał danych niezbędnych dla
            celów rozliczeń podatkowych. W takim wypadku nagroda pozostaje do wyłącznej dyspozycji Organizatora.
          </li>
          <li>
            Organizator nie odpowiada za błędy i zaniechania laureata Konkursu, jak i Akcji specjalnych związane z
            obowiązkami wynikającymi z odpowiednich przepisów prawa, a w szczególności za konsekwencje finansowe w/w
            błędów.
          </li>
          <li>
            Organizator zastrzega prawo nieprzyznania nagrody Uczestnikowi w sytuacji, gdy Uczestnik podejmuje działania
            sprzeczne z niniejszym Regulaminem.
          </li>
          <li>
            Wszelkie nagrody w Programie zostaną rozliczone zgodnie z obowiązującymi przepisami prawa, w tym przepisami
            ustawy o podatku dochodowym od osób fizycznych.
          </li>
          <li>
            Dla nagrodzonych nagrodą Uczestników, Organizator przewidział także dodatkowe nagrody pieniężne w wysokości
            11,11 % wartości przyznanej nagrody rzeczowej. Dodatkowa nagroda nie zostanie wypłacona Uczestnikowi, lecz
            zostanie potrącona na pokrycie podatku, o którym mowa w zdaniu następnym. Od łącznej wartości nagród nagrody
            stanowiącej sumę wartości nagrody rzeczowej przyznanej ww. Uczestnikowi oraz wartości dodatkowej nagrody
            pieniężnej określonej powyżej, Organizator naliczy właściwy podatek na poczet podatku zryczałtowanego, o
            którym mowa w art. 30 ust. 1 pkt 2 ustawy z dnia 26 lipca 1991 r. o podatku dochodowym od osób fizycznych
            (t.j. Dz.U. z 2018 r., poz. 1509 ze zm.) i potrąci z dodatkowej nagrody pieniężnej określonej powyżej kwotę
            w wysokości 10% wartości nagród na poczet tego podatku. W przypadku zrzeczenia się lub nieodebrania nagrody
            rzeczowej, podatek od nagrody, o którym mowa powyżej nie zostanie naliczony, a Uczestnik Programu utraci
            prawo do dodatkowej nagrody pieniężnej, o której mowa w niniejszym paragrafie. Za pobranie i odprowadzenie
            podatku, o którym mowa powyżej, do urzędu skarbowego odpowiedzialny jest wyłącznie Organizator, przy czym
            Uczestnik ponosi odpowiedzialność za podanie prawidłowych danych.
          </li>
        </ol>
        <SectionTitle>§6. REZYGNACJA Z UCZESTNICTWA W PROGRAMIE</SectionTitle>
        <ol>
          <li>
            Uczestnik może w każdej chwili zrezygnować z udziału w Programie. W tym celu musi przesyłać na adres
            <a href="mailto:kontakt@topdekarze.pl">kontakt@topdekarze.pl</a> stosowną informację.
          </li>
          <li>
            Uczestnik, który zrezygnował z Programu, traci wszystkie Punkty bez możliwości ich bezpłatnego przekazania,
            odsprzedaży i zbycia na korzyść innego Uczestnika oraz jego Konto zostaje usunięte z Platformy.
          </li>
        </ol>
        <SectionTitle>§7. REKLAMACJE</SectionTitle>
        <ol>
          <li>
            Uczestnik ma możliwość zgłoszenia reklamacji w czasie trwania Programu i po jego zakończeniu, najpóźniej
            w ciągu 14 dni, licząc od dnia zakończenia Programu(decyduje data wpływu reklamacji do Organizatora).
          </li>
          <li>
            Reklamacja powinna być złożona e-mailowo na adres:{' '}
            <a href="mailto:topdekarz@wooagency.pl">topdekarz@wooagency.pl.</a>
          </li>
          <li>
            Reklamacja powinna zawierać co najmniej dane Uczestnika: imię, nazwisko, dokładny adres Uczestnika, numer
            telefonu kontaktowego oraz powód zgłoszenia reklamacji.
          </li>
          <li>Reklamacje zostaną rozpatrzone w terminie 14 dni od zgłoszenia.</li>
          <li>
            Niezależnie od postępowania reklamacyjnego Uczestnikowi przysługuje prawo do dochodzenia roszczeń zgodnie
            z przepisami obowiązującego prawa
          </li>
        </ol>
        <SectionTitle>§8. DANE OSOBOWE</SectionTitle>
        <ol>
          <li>
            Dekarz zgłaszający się do udziału w Programie wyraża zgodę na przetwarzanie jego danych osobowych zgodnie z
            przepisami prawa i poniższymi zasadami.
          </li>
          <li>
            Administratorem Danych Osobowych dekarza jest CREATON Polska sp. z o.o. z siedzibą w Olkuszu (32-300) ul.
            Wspólna 6 („Administrator”).
          </li>
          <li>
            Dane osobowe dekarza są przetwarzane:
            <ol>
              <li>
                w celu przeprowadzenia Programu podstawą prawną przetwarzania danych jest prawnie uzasadniony interes
                Administratora (art. 6 ust. 1 lit. f) Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679
                z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych
                i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
                o ochronie danych) („Rozporządzenie”); prawnie uzasadniony interes Administratora polega na 
                przeprowadzeniu Programu (w tym przekazaniu nagrody),
              </li>
              <li>
                w celu ustalenia lub dochodzenia ewentualnych roszczeń lub obrony przed takimi roszczeniami przez
                Administratora; podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Administratora
                (art. 6 ust. 1 lit. f) Rozporządzenia),
              </li>
              <li>
                w celach wypełnienia obowiązków prawnych podatkowo-rachunkowych (art. 6 ust. 1 lit. c) Rozporządzenia),
              </li>
              <li>
                w celu prowadzenia działań handlowo-marketingowych produktów dystrybuowanych przez Administratora, co
                będzie odbywać się na podstawie zgody wyrażonej przez Dekarza (art. 6 ust. 1 lit. a) Rozporządzenia).
              </li>
            </ol>
          </li>
          <li>
            Dekarz może skontaktować się we wszystkich sprawach związanych z danymi osobowymi i w celu skorzystania z
            uprawnień z nimi związanymi poprzez e-mail:{' '}
            <a href="mailto:daneosobowe@creaton.pl">daneosobowe@creaton.pl</a> lub pisemnie na adres siedziby
            Administratora (w przypadku korespondencji na adres siedziby Administratora, prosimy o zamieszczenie dopisku
            „Dane osobowe”).
          </li>
          <li>
            Dane osobowe dekarza mogą być przekazywane podmiotom świadczącym usługi na rzecz Administratora związane z
            realizacją Programu m.in. podmiotom świadczącym usługi kurierskie, firmom transportowym, wykonawcom i
            dystrybutorom produktów Administratora, dostawcom systemów informatycznych, księgowych i usług IT, podmiotom
            świadczącym usługi archiwizacji dokumentów. Dane osobowe Uczestników Programu mogą być także przekazywane
            uprawnionym organom w zakresie wymaganym przez obowiązujące przepisy prawa.
          </li>
          <li>
            Dane osobowe dekarza będą przetwarzane przez okres niezbędny do przeprowadzenia Programu, a w zakresie
            niezbędnym do dochodzenia ewentualnych roszczeń wynikających z Programu lub obrony przed roszczeniami przez
            Administratora – przez okres ich przedawnienia. Po tym okresie dane będą przetwarzane jedynie w celu i w
            zakresie wynikającym z przepisów prawa.
          </li>
          <li>
            Dane osobowe dekarza przetwarzane na podstawie zgody będą przetwarzane, jak długo zgoda nie zostanie
            wycofana lub Administrator nie stwierdzi zdezaktualizowania się danych. Cofnięcie zgody lub wniesienie
            sprzeciwu co do przetwarzania danych w celu marketingu bezpośredniego nie wpływa na zgodność z prawem
            przetwarzania danych, jakie nastąpiło przed tymi czynnościami.
          </li>
          <li>
            Dekarzowi przysługuje prawo: dostępu do swoich danych osobowych oraz prawo żądania ich sprostowania, ich
            usunięcia lub ograniczenia przetwarzania oraz prawo do wniesienia sprzeciwu wobec ich przetwarzania.{' '}
          </li>
          <li>
            Dekarzowi przysługuje także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych
            osobowych, gdy uzna, że przetwarzanie danych osobowych narusza przepisy Rozporządzenia.{' '}
          </li>
          <li>
            Wzięcie udziału w Programie jest dobrowolne. Podanie danych osobowych jest niezbędne do udziału dekarza w
            Programie – niepodanie danych osobowych lub ich cofnięcie przed zakończeniem Programu uniemożliwi wzięcie
            udziału w Programie i przekazanie nagrody.
          </li>
          <li>
            Za zgodą Dekarza wyrażoną w dowolnej formie Administrator będzie miał prawo do przetwarzania także jego
            danych osobowych w zakresie wizerunku utrwalonego na zdjęciu lub filmie w celu realizacji zadnia
            konkursowego w ramach Platformy konkursowej.
          </li>
        </ol>
        <SectionTitle>§9. ODPOWIEDZIALNOŚĆ ORGANIZATORA</SectionTitle>
        <ol>
          <li>
            Organizator nie odpowiada za podanie przez dekarza niepełnych bądź nieprawdziwych danych podczas wypełniania
            Formularza zgłoszeniowego, edycji danych lub wydania nagrody i zgłoszenia jej opodatkowania. Nie ponosi
            również odpowiedzialności za konsekwencje udostępnienia przez Dekarza stronom trzecim swoich danych
            służących do rejestracji lub logowania do Programu.
          </li>
          <li>
            Odpowiedzialność Organizatora ograniczona jest względem Uczestnika do wysokości wartości nagród uzyskanych w
            Programie przez Uczestnika.
          </li>
          <li>
            Organizator nie ponosi odpowiedzialności za terminowość doręczenia przesyłek przez pocztę polską lub firmę
            kurierską.
          </li>
          <li>Organizator nie ponosi odpowiedzialności z tytułu rękojmi za wady fizyczne nagród.</li>
        </ol>
        <SectionTitle>§10. POSTANOWIENIA KOŃCOWE</SectionTitle>
        <ol>
          <li>
            Regulamin obowiązuje od chwili jego zaakceptowania przez Dekarza podczas rejestracji na Platformie Programu
            przez okres trwania Programu.
          </li>
          <li>
            Regulamin zostanie opublikowany i będzie dostępny dla każdego Uczestnika na Platformie, z możliwością jego
            pobrania w formie pliku PDF.
          </li>
          <li>
            Gdyby którekolwiek z postanowień zostało uznane za nieważne, Organizatorowi przysługuje prawo do zastąpienia
            tego postanowienia, postanowieniem zgodnym z prawem i zbliżonym w swej treści do postanowienia uchylonego.
          </li>
          <li>
            Organizator zastrzega sobie prawo do zmiany Regulaminu. Informacja o zmianie zostanie podana na Platformie.
          </li>
          <li>
            Organizator zastrzega sobie prawo do wcześniejszego zakończenia Programu z przyczyn niezależnych od
            Organizatora, za uprzednim zawiadomieniem Uczestników za pośrednictwem Platformy. Zawiadomienie zostanie
            przesłane do Uczestników na co najmniej 21 dni przed planowanym zakończeniem Programu. W sytuacji
            wcześniejszego zakończenia Programu jego rozliczenie i przyznanie nagród zostanie dokonane w oparciu o
            rezultaty punktacji zdobytej przez Uczestników na ostatni dzień nowego terminu zakończenia Programu.
          </li>
          <li>
            We wszystkich sprawach dotyczących Programu, a nieuregulowanych Regulaminem, mają zastosowanie odpowiednie
            przepisy polskiego prawa.
          </li>
          <li>
            Sądem właściwym do rozpatrywania wszelkich sporów wynikłych w związku z Programem jest sąd właściwy dla
            siedziby Organizatora.{' '}
          </li>
          <li>
            W razie problemów lub pytań dotyczących Programu, w tym funkcjonowania Platformy, Uczestnik może kontaktować
            się z Organizatorem na adres e-mail: <a href="mailto:kontakt@topdekarze.pl">kontakt@topdekarze.pl</a>.
          </li>
        </ol>

        <ButtonsContainer center>
          {user && !user.rulesAccepted ? (
            <Button click={() => accept()} big primary text="Akceptuję regulamin" />
          ) : (
            <Button to="/" big primary text="Ok, zamknij" />
          )}
          <Button blank text="Pobierz PDF" to="/assets/regulamin_1_09.pdf" />
        </ButtonsContainer>
      </Container>
    </Main>
  );
};

export default Rules;
