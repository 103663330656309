import React from 'react';
import { useDispatch } from 'react-redux';
import ApiService from '../../../services/api-service';
import { history } from '../../../App';
import { Column, Container, Row } from '../../Layout';
import Icon from '../Icon';
import { successNotification } from '../../../actions/notifications';
import { __ } from '../../../helpers/i18n';

import './TransactionVerifyMenu.scss';

interface Props {
  transactionId: string;
}

const TransactionVerifyMenu = ({ transactionId }: Props) => {
  const dispatch = useDispatch();

  const transactionAction = async (actionType: 'reject' | 'accept') => {
    const endpointUrl = `transaction/${actionType}-transaction/${transactionId}`;
    const notificationText = __(`application.transaction-${actionType}`);

    await ApiService.callFetch('PATCH', endpointUrl, () => {
      dispatch(successNotification(notificationText));
      history.push('/dashboard/transactions/list');
    });
  };

  return (
    <section className="transaction-verify-actions">
      <Container>
        <Row>
          <Column className="reject-box box" onClick={() => transactionAction('reject')} role="button" autoWidth>
            <div className="content">
              <Icon icon="transaction-reject" />
              Odrzuć
            </div>
          </Column>
          <Column className="accept-box box" onClick={() => transactionAction('accept')} role="button" autoWidth>
            <div className="content">
              <Icon icon="transaction-accept" />
              Zaakceptuj
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  );
};

export default TransactionVerifyMenu;
