import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../reducers';
import useHeader from '../../../hooks/useHeader';
import { Main, Container } from '../../../components/Layout';
import { getUserDetails } from '../../../actions/user';
import { ImportCsvForm, AddUserForm } from '../../../components/Form';
import './UsersImporting.scss';
import SectionTitle from '../../../components/Common/SectionTitle';

const UsersImporting: FC = () => {
  useHeader('Importowanie', '/dashboard');

  const user = useSelector((state: RootState) => state.user.details);
  const dispatch = useDispatch();

  if (!user) dispatch(getUserDetails());

  if (user) {
    return (
      <Main>
        <Container fluid>
          <SectionTitle>Importowanie użytkowników z pliku csv</SectionTitle>
          <ImportCsvForm />
          <SectionTitle>Dodaj użytkownika wykorzystując formularz</SectionTitle>
          <AddUserForm />
        </Container>
      </Main>
    );
  }
  return null;
};

export default UsersImporting;
