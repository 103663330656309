import React from 'react';
import { Column, Container, ButtonsContainer } from '../../Layout';
import { __ } from '../../../helpers/i18n';
import { history } from '../../../App';
import './ActionBanner.scss';
import clsx from 'clsx';
import { Button } from '..';

interface Props {
  fullSize?: boolean;
  image?: string;
  path?: string;
  text?: string;
}

const ActionBanner = ({ fullSize, image, text, path }: Props) => {
  const styles = {
    backgroundImage: `url(/assets/images/${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom 0 right 0',
    backgroundRepeat: 'no-repeat',
    fill: '#fff',
    opacity: '1',
    cursor: 'pointer',
  };
  return (
    <Container>
      <div className="action-banner">
        <img src={`/assets/images/${image}`} />
        <div className="banner-overlay">
          {!!path && (
            <ButtonsContainer>
              <Button primary big to={path} text="Przejdź" />
            </ButtonsContainer>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ActionBanner;
