import React, { FC } from 'react';
import { UserTableRes } from 'topdekarze-types';
import { FieldType, usersTableSchema } from 'topdekarze-tables';
import { Main } from '../../../components/Layout';
import ApiTable from '../../../components/Common/ApiTable';
import { InteractiveTableSchema } from '../../../utils/table-schema-utils';
import { __ } from '../../../helpers/i18n';
import useHeader from '../../../hooks/useHeader';
import TableWrapper from '../../../components/Common/TableWrapper';

const MyUsers: FC = () => {
  useHeader('moi dekarze', '/dashboard');

  const scheme = new InteractiveTableSchema(usersTableSchema)
    .removeFields('id', 'isModuleSelected', 'isInverterSelected', 'isConstructionSelected', 'isBoxSelected');

  return (
    <Main className="main-users">
      <TableWrapper>
        <ApiTable scheme={scheme} apiEndpointSubUrl="superuser/find-all-my-users" />
      </TableWrapper>
    </Main>
  );
};

export default MyUsers;
