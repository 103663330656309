import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableSchema, TableSchemaField } from 'topdekarze-tables';
import { Spinner } from '../../../components/Common';
import Table from '../Table';
import debounce from '../../../utils/debouce';
import { apiTable } from '../../../actions';
import SearchBar from '../../Common/SearchComponent';

interface Props {
  fetchApiTableData: (url: string, method?: string) => void;
  loading: boolean;
  data: any;
  scheme: TableSchema;
  apiEndpointSubUrl: string;
  apiEndpointMethod?: string; // Defaults to GET
  fields?: any;
  noSearch?: boolean;
  highlight?: (row: any) => boolean;
  dehighlight?: (row: any) => boolean;
}
class ApiTable<T> extends Component<Props> {
  private debouncedFetch: () => void;

  state = {
    searchQuery: '',
    searchBy: '',
    page: 1,
    sortBy: '',
    sortDirBack: false,
    loading: true,
    data: {
      countTotal: 0,
      items: [],
    },
  };

  constructor(props: Props) {
    super(props);
    this.debouncedFetch = debounce(this.fetch, 400);
    const { scheme } = props;
    const defaultSearchField = scheme.fields.find(
      (field) => ['string', 'number'].indexOf(typeof field.defaultSearch) > -1,
    );
    const currSort = this.getCurrentSort();
    if (defaultSearchField) {
      this.state.searchBy = defaultSearchField.field;
      this.state.searchQuery = String(defaultSearchField.defaultSearch);
    }
    if (currSort) {
      this.state.sortBy = currSort.sortBy;
      this.state.sortDirBack = currSort.sortDirBack;
    }
  }

  private async fetch() {
    const { fetchApiTableData, apiEndpointMethod } = this.props;
    await fetchApiTableData(this.buildUrl(), apiEndpointMethod);
  }

  private getCurrentSort(): {
    sortBy: string;
    sortDirBack: boolean;
  } {
    const { sortBy, sortDirBack } = this.state;
    const { scheme } = this.props;
    console.log('SCHEME', scheme, sortBy);
    if (sortBy !== '') {
      return {
        sortBy,
        sortDirBack,
      };
    } else {
      const field = scheme.fields.find((f) => f.defaultSort === true) as TableSchemaField;
      if (!field)
        return {
          sortBy: '',
          sortDirBack: false,
        };
      return {
        sortBy: field.field,
        sortDirBack: field.oppositeSortDir !== true,
      };
    }
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps: Props) {
    const { data } = this.props;
    if (data.uuid !== prevProps.data.uuid) {
      this.fetch();
    }
  }

  handlePageChange = (newPage: number) => {
    const { page } = this.state;
    if (page === newPage) return;
    this.setState(
      {
        page: newPage,
      },
      this.fetch,
    );
  };

  handleSortChange = (sortBy: string, sortDirBack: boolean) => {
    const currSort = this.getCurrentSort();
    console.log('CURR', currSort, sortBy, sortDirBack);
    if (currSort.sortBy === sortBy && currSort.sortDirBack === sortDirBack) return;

    this.setState(
      {
        sortBy,
        sortDirBack,
      },
      this.fetch,
    );
  };

  private buildUrl(): string {
    const { apiEndpointSubUrl } = this.props;
    const { searchBy, searchQuery, page, sortBy, sortDirBack } = this.state;
    console.log('SEARCH', searchBy, sortDirBack);
    return `${apiEndpointSubUrl}?${new URLSearchParams({ searchBy, searchQuery, page, sortBy, sortDirBack } as any)}`;
  }

  handleSearchChange = (searchBy: string, searchQuery: string) => {
    const { searchBy: searchByNow, searchQuery: searchQueryNow } = this.state;
    if (searchBy === searchByNow && searchQuery === searchQueryNow) return;
    this.setState(
      {
        searchBy,
        searchQuery,
      },
      this.debouncedFetch,
    );
  };

  render() {
    const { page, searchQuery, searchBy } = this.state;
    const { loading, data, scheme, noSearch, highlight, dehighlight } = this.props;
    let table;
    if (loading) {
      table = <Spinner overlay transparent />;
    } else {
      table = (
        <Table
          data={data}
          scheme={scheme}
          initialPage={page}
          sort={this.getCurrentSort()}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSortChange}
          highlight={highlight}
          dehighlight={dehighlight}
        />
      );
    }
    return (
      <>
        {data &&
          !noSearch &&
          (data.countTotal > 0 || searchQuery) &&
          SearchBar(
            scheme.fields,
            {
              searchBy,
              searchQuery,
            },
            null,
            this.handleSearchChange,
          )}
        <div className="api-table">{table}</div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  data: state.apiTable,
  loading: state.apiTable.loading,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ fetchApiTableData: apiTable.fetchApiTableData }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ApiTable);
