import React, { FC, useEffect, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import { Link } from 'react-router-dom';
import { Container } from '../../Layout';
import Image from '../Image';
import Icon from '../Icon';

import './BannersCarousel.scss';
import ApiService from '../../../services/api-service';
import { EventTableRes } from 'topdekarze-types';
import Spinner from '../Spinner';
import { Alert } from '..';

const BannersCarousel: FC<{}> = () => {
  const [events, setEvents] = useState<{ id: string; image: string }[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    await ApiService.callFetch('GET', 'event/list', (data: EventTableRes[]) => {
      setEvents(data.map((e) => ({ id: e.id, image: `${ApiService.url}event/event-image/${e.id}` })));
      setLoading(false);
    });
  };
  if (loading)
    return (
      <Container>
        <Spinner light />
      </Container>
    );
  if (!events.length)
    return (
      <Container className="no-actions-notice">
        <Alert text="Aktualnie brak zadań specjalnych" type="notice" />
      </Container>
    );
  return (
    <Container className="actions-banner carousel">
      <Carousel
        arrows
        arrowLeft={<Icon className="carousel-icon" icon="left-arrow" />}
        arrowRight={<Icon className="carousel-icon" icon="right-arrow" />}
        addArrowClickHandler
        draggable
        slidesPerPage={1}
      >
        {events.map(({ id, image }) => (
          <Link to={`/dashboard/special-tasks/${id}`}>
            <div key={id} className="carousel-item">
              <Image absolute image={image} />
            </div>
          </Link>
        ))}
      </Carousel>
    </Container>
  );
};

export default BannersCarousel;
