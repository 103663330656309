import React, { FC, useEffect } from 'react';
import DashboardItem from '../DashboardItem';
import { Row, Column } from '../../../../components/Layout';
import Icon from '../../../../components/Common/Icon';
import './DashboardBottomSection.scss';
import { history } from '../../../../App';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { getUserDetails, getPoints } from '../../../../actions/user';
import { Spinner, Button } from '../../../../components/Common';
import { UserRole } from 'topdekarze-types';

const DashboardBottomSection: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.details);
  const { userPoints, userPosition } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getPoints());
  }, []);

  if (!user) return <Spinner />;

  return (
    <Row center className="dashboard-bottom">
      <Column xs={3}>
        {user!.role !== UserRole.Admin && (
          <DashboardItem itemIcon="star" itemValue={userPoints || 0} itemName="punktów" />
        )}
      </Column>
      <Icon icon="line-long" className="line-icon" />
      <Column xs={3}>
        {user!.role === UserRole.Admin ? (
          <Button small to="/dashboard/rating/main-rating" text="Ranking" />
        ) : (
          <DashboardItem
            to="/dashboard/rating/main-rating"
            itemIcon="chart"
            itemValue={userPosition || 0}
            itemName="miejsce"
          />
        )}
      </Column>
      <Icon icon="line-long" className="line-icon" />
      <Column xs={3}>
        <DashboardItem itemIcon="gear" itemName="ustawienia" to="/dashboard/my-profile" />
      </Column>
    </Row>
  );
};

export default DashboardBottomSection;
