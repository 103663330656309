import React, { FC } from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { FormFieldType } from 'itf_formbuilder';
import flatpickr from 'flatpickr';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import FormContainer from '../../Layout/FormContainer';
import { Button, Input, Spinner } from '../../Common';
import ApiService from '../../../services/api-service';
import { history } from '../../../App';
import { __ } from '../../../helpers/i18n';
import { successNotification } from '../../../actions/notifications';
import { formOnTranslateString } from '../../../utils/trans-form';

import '../../../config/static-select.scss';
import { ButtonsContainer } from '../../Layout';

interface Props {
  files: File[];
  eventId?: string;
}

const AddTransactionForm: FC<Props> = ({ files, eventId }) => {
  const dispatch = useDispatch();

  return (
    <FormContainer grid>
      <ItfApiForm
        formId="addTransactionForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        useFormDataInsteadOfPlainObject
        schemaFetcher={() => ApiService.loadForm(eventId ? `transaction/form/add/${eventId}` : 'transaction/form/add/')}
        sendFetcher={(formId: string, values: any) => {
          files.forEach((file, index) => {
            values.append('transaction', file, file.name);
          });
          return ApiService.sendForm(eventId ? `transaction/add/${eventId}` : 'transaction/add', values);
        }}
        submitButton={
          <ButtonsContainer center>
            <Button secondary type="submit" text={__('application.send')} />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
        thirdPartyComponents={{
          flatpickr,
          ReactSelect: Select,
          ReactCreatableSelect: Creatable,
        }}
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case FormFieldType.Text:
              return (
                <Input
                  type={field.options.type || field.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: any) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            default:
              return null;
          }
        }}
        onSavedSuccessfully={() => {
          dispatch(successNotification('Dodano realizację'));
          // history.push('/dashboard');
        }}
      />
    </FormContainer>
  );
};

export default AddTransactionForm;
