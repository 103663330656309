import React, { FC } from 'react';
import './DesktopNav.scss';
import NavItem from '../NavItem';
import { DashboardNav } from '../../../types/navs';
import SocialsPanel from '../SocialsPanel';
import Icon from '../../Common/Icon';
import { __ } from '../../../helpers/i18n';
import Logout from '../Logout';

interface Props {
  menuItems: DashboardNav[];
}

const DesktopNav: FC<Props> = ({ menuItems }) => (
  <div className="desktop-nav">
    <ul>
      {menuItems.map((item) => {
        const { iconName, path } = item;
        return <NavItem key={iconName} iconName={iconName} path={path} />;
      })}
    </ul>
    <Logout />
    <Icon icon="nav-line" className="nav-line-icon" />
    <li className="nav-follow">
      <p>{__('application.followUs')}</p>
    </li>
    <SocialsPanel column />
  </div>
);

export default DesktopNav;
