import React from 'react';
import useListArticle from '../../../../hooks/articles/useListArticle';
import { Spinner } from '../../../../components/Common';
import { Container, Main, Row } from '../../../../components/Layout';
import Card from '../../../../components/Common/Card';
import EntryText from '../../../../components/Common/EntryText';
import useHeader from '../../../../hooks/useHeader';
import './ListArticle.scss';

const ListArticle = (): JSX.Element => {
  useHeader('Baza wiedzy', '/dashboard');
  const { articles, isLoading, isFirst } = useListArticle();

  if (isLoading) return <Spinner />;

  return (
    <Main>
      <Container>
        <EntryText>
          Rozwijaj swoją wiedzę, poznawaj nowe techniki i praktyczne uwagi ekspertów CREATON Polska. Wierzymy, że
          przekazywana wiedza, pomoże Ci w codziennej pracy!
        </EntryText>
        <Row as="ul">
          {articles.map((article, index) => (
            <Card
              key={article.id}
              className="article"
              title={article.title}
              isFirst={isFirst(index)}
              imgUrl={`article/image/${article.id}`}
              to={`/dashboard/articles/view/${article.id}`}
              isTitleOnPhoto={isFirst(index)}
            />
          ))}
        </Row>
      </Container>
    </Main>
  );
};

export default ListArticle;
