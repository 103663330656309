import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { UserRole } from 'topdekarze-types';
import AddArticle from './Add';
import RouteAdmin from '../../../components/Routes/RouteAdmin';
import ViewArticle from './View';
import ManageArticles from './Manage';
import ListArticle from './List';
import { RootState } from '../../../reducers';

const Articles: FC = () => {
  const user = useSelector((state: RootState) => state.user.details);
  return (
    <Switch>
      <Route
        exact
        path="/dashboard/articles"
        render={() =>
          [UserRole.Admin, UserRole.SuperAdmin].includes(user!.role) ? (
            <Redirect to="/dashboard/articles/manage" />
          ) : (
            <Redirect to="/dashboard/articles/list" />
          )
        }
      />
      <RouteAdmin exact path="/dashboard/articles/add" component={AddArticle} />
      <Route exact path="/dashboard/articles/list" component={ListArticle} />
      <RouteAdmin exact path="/dashboard/articles/manage" component={ManageArticles} />
      <Route path="/dashboard/articles/view/:articleId" component={ViewArticle} />
    </Switch>
  );
};

export default Articles;
