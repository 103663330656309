// @ts-nocheck

import React, { FC } from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import CKEditor from '@ckeditor/ckeditor5-react';
// import CKEditorEditorBuild from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import CKEditorEditorBuild from 'topdekarze-ckeditor/dist/ckeditor';
import { FormContainer, ButtonsContainer } from '../../Layout';
import { Button, Spinner } from '../../Common';
import ApiService from '../../../services/api-service';
import { __ } from '../../../helpers/i18n';
import { formOnTranslateString } from '../../../utils/trans-form';
import { successNotification } from '../../../actions/notifications';
import { AddArticleFormSettings } from '../../../hooks/articles/useAddArticlee';
import useHeader from '../../../hooks/useHeader';
import { notifications } from '../../../actions';

// import './AddArticleForm.scss';

interface Props {
  handleEditorChange: any;
  content: string | null;
  articleId?: string;
  formSettings: (articleId?: string) => AddArticleFormSettings;
}

const AddArticlesForm: FC<Props> = ({ handleEditorChange, content, articleId, formSettings }) => {
  useHeader('Dodaj artykuł', '/dashboard');
  const dispatch = useDispatch();

  const settings = formSettings(articleId);
  const { fetchUrl, sendMethod, sendUrl, buttonText, notification } = settings;

  return (
    <FormContainer className="add-article-form">
      <ItfApiForm
        formId="addArticleForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        useFormDataInsteadOfPlainObject
        schemaFetcher={() => ApiService.loadForm(fetchUrl)}
        sendFetcher={(formId: string, values: any) => ApiService.sendForm(sendUrl, values, sendMethod)}
        submitButton={
          <ButtonsContainer>
            <Button primary type="submit" text={__(buttonText)} />
          </ButtonsContainer>
        }
        thirdPartyComponents={{
          ReactSelect: Select,
          ReactCreatableSelect: Creatable,
          CKEditor,
          CKEditorEditorBuild,
        }}
        onTranslateString={formOnTranslateString}
        onSavedSuccessfully={() => {
          dispatch(successNotification(__(notification)));
        }}
      />
    </FormContainer>
  );
};

export default AddArticlesForm;
