import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRole } from 'topdekarze-types';
import { RootState } from '../../reducers';

interface Props {
  component: any;
  exact?: boolean;
  path?: string;
}

const RouteSuperUser = ({ exact, path, component }: Props): JSX.Element => {
  const role = useSelector((state: RootState) => state.user.details?.role);

  if (role === UserRole.SuperUser || role === UserRole.Admin) {
    return <Route exact={exact} path={path} component={component} />;
  } else {
    return <Route render={() => <Redirect to="/" />} />;
  }
};

export default RouteSuperUser;
