import React from 'react';
import Image from '../Image';

import './DashboardBackground.scss';

interface Props {
  backgroundImage?: string;
}

const DashboardBackground = ({ backgroundImage }: Props) => (
  <>
    <div className="background-image">
      <Image image={backgroundImage || 'background.png'} />
    </div>
  </>
);

export default DashboardBackground;
