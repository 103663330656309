import React, { FC } from 'react';
import { ArticleRes } from 'topdekarze-types';
import { Link } from 'react-router-dom';
import { Container, Column, Row } from '../../Layout';
import ApiService from '../../../services/api-service';
import Image from '../Image';
import PageTitle from '../PageTitle';

import './HomeNewsDesktop.scss';

interface Props {
  content: ArticleRes[];
}

const HomeNewsDesktop: FC<Props> = ({ content }) => (
  <section>
    <Container>
      <Row className="news-box-wrapper">
        <Column xs={12}>
          <PageTitle>Aktualności</PageTitle>
        </Column>
        <Column className="left-side" xs={8}>
          <Link to="/dashboard/main-prize" className="news-box main">
            <Image image="baner_novaPaka_v3.jpg" />
          </Link>
        </Column>
        <Column className="right-side" xs={4}>
          <Row>
            {content &&
              content.length > 0 &&
              content.map((singleBox) => (
                <Column xs={12} className="news-box-column" key={singleBox.id}>
                  <Link to={`/dashboard/articles/view/${singleBox.id}`} className="news-box">
                    <img src={`${ApiService.url}article/image/${singleBox.id}`} alt="" />
                    <div className="title">{singleBox.title}</div>
                  </Link>
                </Column>
              ))}
          </Row>
        </Column>
      </Row>
    </Container>
  </section>
);

export default HomeNewsDesktop;
