import { LoginRes, AuthLoginResult } from 'topdekarze-types';

interface Login {
  type: 'LOGIN_SUCCESS';
  payload: AuthLoginResult;
}
interface GetUserDetails {
  type: 'GET_USER_DETAILS_SUCCESS';
  payload: LoginRes;
}
interface SetUser {
  type: 'SET_USER';
  payload: LoginRes;
}
interface GetUserPoints {
  type: 'GET_USER_POINTS_SUCCESS';
  payload: {
    position: number;
    points: number;
    pointsToTop: number;
  };
}
interface LoadingUser {
  type: 'LOADING_USER';
  payload: boolean;
}
interface Logout {
  type: 'LOGOUT';
}
type Action = Login | GetUserDetails | Logout | LoadingUser | GetUserPoints | SetUser;
export interface UserState {
  loading: boolean;
  details: LoginRes | null;
  userPoints: null | number;
  userPosition: null | number;
  pointsToTop: null | number;
}
const initialState: UserState = {
  loading: true,
  details: null,
  userPoints: null,
  userPosition: null,
  pointsToTop: null,
};
export default (state = initialState, action: Action): UserState => {
  switch (action.type) {
    case 'GET_USER_DETAILS_SUCCESS': {
      const user = action.payload;
      return {
        ...state,
        details: user,
      };
    }
    case 'SET_USER': {
      const user = action.payload;
      return {
        ...state,
        details: user,
      };
    }
    case 'GET_USER_POINTS_SUCCESS': {
      const { points, position, pointsToTop } = action.payload;
      return {
        ...state,
        userPoints: points,
        userPosition: position,
        pointsToTop,
      };
    }
    case 'LOADING_USER':
      return { ...state, loading: action.payload };
    case 'LOGOUT':
      console.log('LOGOUT');
      return { ...state, details: null };
    default:
      return state;
  }
};
