/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import jsonToFormData from '@ajoelp/json-to-formdata';
import { useDispatch } from 'react-redux';
import { ApiErrorCode } from 'topdekarze-types';
import { AddQuizPhoto } from '../../types/addQuiz';
import ApiService from '../../services/api-service';
import { errorNotification, successNotification } from '../../actions/notifications';
import { history } from '../../App';

interface UseCreateQuizForm {
  mainPhoto: AddQuizPhoto | undefined;
  questionImages: AddQuizPhoto[];
  handleImagePreview: (e: any) => void;
  handleQuestionImagePreview: (e: any) => void;
  submit: (data: any, e: any) => void;
}

export const useCreateQuizForm = (): UseCreateQuizForm => {
  const [mainPhoto, setMainPhoto] = useState<AddQuizPhoto>();
  const [questionImages, setQuestionImages] = useState<AddQuizPhoto[]>([]);
  const dispatch = useDispatch();

  const checkFileSize = (file: File) => {
    // bytes to mb === 2mb
    const maxFileSize = 2000000;
    if (file?.size > maxFileSize) {
      dispatch(errorNotification(ApiErrorCode.FileIsTooLarge));
      return false;
    }
    return true;
  };

  const handleImagePreview = (e: any) => {
    if (checkFileSize(e.target.files[0])) {
      const tempImage = {
        previewUrl: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      };
      setMainPhoto(tempImage);
    }
  };

  const handleQuestionImagePreview = (e: any) => {
    if (checkFileSize(e.target.files[0])) {
      const tempImage = {
        previewUrl: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      };
      setQuestionImages((questionImages) => [...questionImages, tempImage]);
    }
  };
  const prepareFormData = (data: any) => jsonToFormData(data);

  const submit = (data: any, e: any) => {
    data.pointsPerQuestion = Number(data.pointsPerQuestion);
    data.questions.forEach(
      (singleQuestion: any) => (singleQuestion.answer[singleQuestion.answer.isCorrect].isCorrect = true),
    );
    const dateSplitted = {
      dateFrom: String(data.date[0]),
      dateTo: String(data.date[1]),
    };
    const formData = prepareFormData({ ...data, ...dateSplitted });
    ApiService.callFetch(
      'POST',
      'quiz/add',
      () => {
        dispatch(successNotification('Poprawnie utworzono quiz!'));
        history.push('/dashboard/quizzes/manage');
      },
      null,
      formData,
      true,
    );
  };

  return {
    submit,
    handleQuestionImagePreview,
    handleImagePreview,
    mainPhoto,
    questionImages,
  };
};
