/* eslint-disable no-nested-ternary */
import React from 'react';
import useArticlePreview, { Mode } from '../../../../hooks/articles/useArticlePreview';
import { Column, Container, Main, Row, ButtonsContainer } from '../../../../components/Layout';
import { Alert } from '../../../../components/Common';
import PageTitle from '../../../../components/Common/PageTitle';
import AddArticlesForm from '../../../../components/Form/AddArticlesForm';
import useAddArticle from '../../../../hooks/articles/useAddArticlee';
import ChangeMode from '../../../../components/Common/ChangeMode';
import Button from '../../../../components/Common/Button';
import { sanitize } from '../../../../utils/sanitize';
import { history } from '../../../../App';

import './Preview.scss';

const ArticlePreview = (): JSX.Element => {
  const { handleEditorChange, returnFormSettings } = useAddArticle();
  const { article, isLoading, getPhoto, changeMode, mode, isAdmin } = useArticlePreview();

  let content = <Alert type="error" text="Nie znaleziono żądanego artykułu." />;

  if (article) {
    content = (
      <>
        {isAdmin && (
          <Row>
            <ChangeMode mode={mode} changeMode={changeMode} />
          </Row>
        )}
        <Row>
          {isLoading ? (
            <Column xs={12}>
              <Alert type="notice" text="ładowanie" />
            </Column>
          ) : mode === Mode.Preview ? (
            <>
              <Column>
                <PageTitle>{article.title}</PageTitle>
              </Column>
              <Column className="img-wrapper">
                <img className="preview-main-photo" src={getPhoto()} alt="" />
              </Column>
              <div dangerouslySetInnerHTML={{ __html: sanitize(article.content) }} className="article-content" />
              {article.quiz && (
                <Column center>
                  <ButtonsContainer center>
                    <Button
                      secondary
                      click={() => history.push(`/dashboard/quizzes/view/${article.quiz.id}/start`)}
                      text="Rozwiąż quiz"
                    />
                  </ButtonsContainer>
                </Column>
              )}
            </>
          ) : (
            <AddArticlesForm
              formSettings={returnFormSettings}
              articleId={article.id}
              content={article.content}
              handleEditorChange={handleEditorChange}
            />
          )}
        </Row>
      </>
    );
  }

  return (
    <Main>
      <Container>{content}</Container>
    </Main>
  );
};

export default ArticlePreview;
