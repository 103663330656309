import React from 'react';
import { ArticleTableRes, QuizTableRes } from 'topdekarze-types';
import { articleTableSchema } from 'topdekarze-tables';
import { useDispatch } from 'react-redux';
import { Main, Container, ButtonsContainer } from '../../../../components/Layout';
import ApiTable from '../../../../components/Common/ApiTable';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import TableWrapper from '../../../../components/Common/TableWrapper';
import useHeader from '../../../../hooks/useHeader';
import { showModal, hideModal } from '../../../../actions/modal';
import Confirmation from '../../../../components/Modals/Confirmation';
import ApiService from '../../../../services/api-service';
import { refreshApiTable } from '../../../../actions/api-table';
import { successNotification } from '../../../../actions/notifications';

import { history } from '../../../../App';
import { Button } from '../../../../components/Common';

const Manage = () => {
  useHeader('Lista quizów', '/dashboard/');
  const dispatch = useDispatch();

  const deleteQuiz = async (quiz: QuizTableRes) => {
    await ApiService.callFetch(
      'PATCH',
      `quiz/delete-quiz/${quiz.id}`,
      () => {
        dispatch(refreshApiTable());
        dispatch(successNotification('Poprawnie usunięto quuiz'));
        dispatch(hideModal());
      },
      null,
    );
  };

  const confirmDelete = (quiz: QuizTableRes) => {
    dispatch(
      showModal(
        <Confirmation
          text={`Czy na pewno chcesz usunąć quiz ${quiz.title}?`}
          confirmCallback={() => {
            deleteQuiz(quiz);
          }}
        />,
      ),
    );
  };

  const goToArticle = (article: ArticleTableRes) => {
    history.push(`/dashboard/articles/view/${article.id}`);
  };

  const scheme = new InteractiveTableSchema(articleTableSchema).removeFields('id').addFieldAtTheEnd({
    name: 'Akcje',
    field: 'actions',
    buttons: [
      { name: 'Usuń', type: 'danger', click: confirmDelete },
      // { name: 'Edytuj', type: 'primary', click: goToQuediiz },
    ],
  });

  return (
    <Main>
      <Container>
        <ButtonsContainer>
          <Button small to="/dashboard/quizzes/add" text="Nowy quiz" />
        </ButtonsContainer>
      </Container>
      <TableWrapper>
        <ApiTable scheme={scheme} apiEndpointSubUrl="quiz/table/all" />
      </TableWrapper>
    </Main>
  );
};

export default Manage;
