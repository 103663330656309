import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { TransactionItem } from 'topdekarze-types';
import Carousel from '@brainhubeu/react-carousel';
import ApiService from '../../../../services/api-service';
import useHeader from '../../../../hooks/useHeader';
import { RootState } from '../../../../reducers';
import TransactionVerifyMenu from '../../../../components/Common/TransactionVerifyMenu';
import { Container, Main } from '../../../../components/Layout';
import Icon from '../../../../components/Common/Icon';

import '@brainhubeu/react-carousel/lib/style.css';
import './Transaction.scss';

const Transaction = () => {
  const { id } = useParams();
  const [transaction, setTransaction] = useState<TransactionItem>();
  const user = useSelector((state: RootState) => state.user.details);

  useHeader(`${user?.firstName} ${user?.lastName}`, '/dashboard/transactions/list');

  const getTransactionData = async () => {
    await ApiService.callFetch(
      'GET',
      `transaction/${id}`,
      (data: TransactionItem) => {
        setTransaction(data);
      },
      null,
    );
  };

  useEffect(() => {
    getTransactionData();
  }, []);

  if (transaction) {
    return (
      <Main>
        <Container className="transaction-carousel" fluid>
          <Carousel
            arrows
            arrowLeft={<Icon className="carousel-icon" icon="left-arrow" />}
            arrowRight={<Icon className="carousel-icon" icon="right-arrow" />}
            addArrowClickHandler
            draggable={false}
          >
            {transaction.photos.length > 0 &&
              transaction.photos.map((photoId) => (
                <div key={photoId} className="carousel-item-wrapper">
                  <img src={`${ApiService.url}transaction/get-photos/${photoId}`} alt="" />
                  <div className="description-wrapper">
                    <p className="by-who">
                      {transaction?.user.firstName} {transaction?.user.lastName}
                    </p>
                    {transaction.boughtDate && (
                      <strong>{format(new Date(transaction.boughtDate), 'dd/MM/yyyy')}</strong>
                    )}
                    {transaction.description && <p className="description">{transaction?.description}</p>}
                  </div>
                </div>
              ))}
          </Carousel>
          <TransactionVerifyMenu transactionId={transaction.id} />
        </Container>
      </Main>
    );
  }
  return null;
};

export default Transaction;
