import React from 'react';

import { Spinner } from '../../../../components/Common';
import { Container, Main, Row } from '../../../../components/Layout';
import Card from '../../../../components/Common/Card';
import EntryText from '../../../../components/Common/EntryText';
import useHeader from '../../../../hooks/useHeader';
import PageTitle from '../../../../components/Common/PageTitle';
import './QuizzesList.scss';
import useQuizList from '../../../../hooks/quizzes/useQuizList';

const ListArticle = (): JSX.Element => {
  useHeader('Quizy', '/dashboard');
  const { quizzes, isLoading, newQuizzes, completedQuizzes, endedQuizzes } = useQuizList();

  if (isLoading) return <Spinner />;

  return (
    <Main>
      <Container>
        <EntryText>
          Sprawdź swoją wiedzę i zdobądź dodatkowe punkty do głównego rankingu. W każdym Quizie możesz zdobyć nawet 80
          punktów, a w czasie trwania całego programu nawet tysiące punktów! Quiz możesz rozwiązać tylko raz, nie masz
          możliwości powrotu i zatrzymania się w trakcie jego rozwiązywania. Obserwuj tę zakładkę, pochwal się swoją
          znajomością i pokaż, że jesteś TOP DEKARZEM.
        </EntryText>
        <EntryText>Zapraszamy do pierwszego quizu już 16.09!</EntryText>
        <Row as="ul">
          {quizzes &&
            newQuizzes.map((quiz, index) => (
              <Card
                key={quiz.id}
                className="quiz quizzes-list"
                title={quiz.title}
                to={`/dashboard/quizzes/view/${quiz.id}/${quiz.status === 'W trakcie' ? 'on-running' : 'start'}`}
                imgUrl={`quiz/get-photo/${quiz.id}`}
                isFirst={false}
                completed={false}
                points={`${quiz.maxPoints}PKT`}
                li
              />
            ))}
          {completedQuizzes && completedQuizzes.length > 0 && (
            <>
              <PageTitle className="quiz-list-subtitle">Rozwiązane</PageTitle>
              {completedQuizzes.map((quiz) => (
                <Card
                  key={quiz.id}
                  className="quiz quizzes-list"
                  title={quiz.title}
                  imgUrl={`quiz/get-photo/${quiz.id}`}
                  isFirst={false}
                  completed
                  points={quiz.myPoints !== null ? `${quiz.myPoints} / ${quiz.maxPoints}` : undefined}
                />
              ))}
            </>
          )}
          {endedQuizzes && endedQuizzes.length > 0 && (
            <>
              <PageTitle className="quiz-list-subtitle">Zakończone</PageTitle>
              {endedQuizzes.map((quiz) => (
                <Card
                  key={quiz.id}
                  className="quiz quizzes-list"
                  title={quiz.title}
                  imgUrl={`quiz/get-photo/${quiz.id}`}
                  isFirst={false}
                  completed
                  points={quiz.myPoints !== null ? `${quiz.myPoints} / ${quiz.maxPoints}` : undefined}
                />
              ))}
            </>
          )}
        </Row>
      </Container>
    </Main>
  );
};

export default ListArticle;
