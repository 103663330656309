import React from 'react';
import { FormFieldType } from 'itf_formbuilder';
import { ItfApiForm } from 'itf_formbuilder_react';
import { Link } from 'react-router-dom';
import { AuthLoginRequest, AuthLoginResult } from 'topdekarze-types';
import { useParams } from 'react-router';
import FormContainer from '../../Layout/FormContainer';
import { Spinner, Input, Button } from '../../Common';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';
import { history } from '../../../App';
import { __ } from '../../../helpers/i18n';

import './FirstLoginForm.scss';
import { useDispatch } from 'react-redux';

const FirstLoginForm = () => {
  const { updateToken, userId } = useParams();
  const dispatch = useDispatch();
  return (
    <FormContainer className="first-login-form">
      <ItfApiForm
        formId="firstLoginForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('user/form/first-setting-pass')}
        sendFetcher={(formId: string, values: FormData) =>
          ApiService.sendForm(
            'user/set-new-pass',
            {
              ...values,
              updateToken,
              userId,
            },
            'PATCH',
          )
        }
        submitButton={
          <>
            <Button primary type="submit" text={__('application.login')} />
          </>
        }
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case FormFieldType.Text:
              return (
                <Input
                  type={field.options.type || field.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: any) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
          }
        }}
        onSavedSuccessfully={(data: AuthLoginResult) => {
          dispatch(successNotification(`${__('application.loginCorrect')}`));
          history.push('/login');
        }}
      />
    </FormContainer>
  );
};

export default FirstLoginForm;
