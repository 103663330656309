import React from 'react';
import './ButtonsContainer.scss';
import clsx from 'clsx';

interface Props {
  children: React.ReactNode;
  max?: boolean;
  className?: string;
  center?: boolean;
}

const ButtonsContainer = ({ children, max, center, className }: Props) => (
  <div className={clsx('buttons-container', className, { max, center })}>{children}</div>
);

export default ButtonsContainer;
