import React from 'react';
import { Main, Container, Row, Column, ButtonsContainer } from '../../../components/Layout';
import InviteUserForm from '../../../components/Form/InviteUserForm';
import useHeader from '../../../hooks/useHeader';
import DownContent from '../../../components/Common/DownContent';
import { Button } from '../../../components/Common';

const InviteUser = () => {
  useHeader('Wyślij zaproszenie', '/dashboard');

  return (
    <Main>
      <Container>
        <ButtonsContainer>
          <Button small to="/dashboard/invitations" text="Lista zaproszeń" />
        </ButtonsContainer>
        <InviteUserForm />
      </Container>
    </Main>
  );
};

export default InviteUser;
