import React, { useEffect } from 'react';
import './UserLevel.scss';
import { Container } from '../../../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getPoints } from '../../../../actions/user';
import { RootState } from '../../../../reducers';

const UserLevel = () => {
  const dispatch = useDispatch();
  const { pointsToTop, userPoints } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getPoints());
  }, []);

  const toTop = pointsToTop || 0;
  const points = userPoints || 0;

  const all = points + toTop;

  return (
    <Container>
      <div className="rating-user-level">
        {toTop <= 0 ? (
          <>
            Gratulujemy, jesteś w <strong>TOP30</strong> i masz zagwarantowaną nagrodę!
          </>
        ) : (
          <>
            Brakuje Ci <span className="level-points">{toTop} pkt</span> by wejść do <strong>TOP30</strong> i zdobyć
            gwarantowaną nagrodę!.
            <div className="progress-wrapper">
              <div className="progress-bar" style={{ width: `${Math.round((points / all) | 0)}%` }} />
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default UserLevel;
