import React from 'react';
import useMySuperuser from '../../../hooks/useMySuperuser';
import { SectionTitle } from '..';
import { __ } from '../../../helpers/i18n';
import ApiService from '../../../services/api-service';

import './MySuperuser.scss';

const MySuperuser = () => {
  const { superUser } = useMySuperuser();

  return (
    <>
      <SectionTitle>Twój doradca handlowo-techniczny</SectionTitle>
      <div className="my-superuser">
        <img className="avatar" src={`${ApiService.url}user/superuser-avatar`} alt="Twój dht avatar" />
        <div className="content">
          <SectionTitle>
            {superUser?.firstName} {superUser?.lastName}
          </SectionTitle>
          <a className="contact-data" href={`mailto:${superUser?.email}`}>
            {superUser?.email}
          </a>
          <a className="contact-data" href={`tel:${superUser?.tel}`}>
            {superUser?.tel}
          </a>
        </div>
      </div>
    </>
  );
};

export default MySuperuser;
