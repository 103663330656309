import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row } from '../../Layout';
import { Button, Input } from '../index';
import Image from '../Image';
import Icon from '../Icon';
import { RealizationImages } from '../../../views/Dashboard/Transactions/AddTransaction';
import { clearPreviewImage, removePreviewImage, setImages } from '../../../actions/mutliple-photo';
import { RootState } from '../../../reducers';

const AddMultiplePhoto = () => {
  const dispatch = useDispatch();
  const images = useSelector((state: RootState) => state.multiplePhoto);

  const handleImage = (e: any) => {
    const imagesArray = e.target.files;
    createPreview(imagesArray);
  };

  const createPreview = (imagesList: File[]) => {
    [...imagesList].forEach((file, index) => {
      const previewUrl = URL.createObjectURL(file);
      const newImage: RealizationImages = { previewUrl, file };
      dispatch(setImages(newImage));
    });
  };

  useEffect(
    () => () => {
      dispatch(clearPreviewImage());
    },
    [],
  );

  return (
    <Column className="add-images-module">
      <Row>
        <Column className="desktop-input" md={8}>
          <div className="content-wrapper">
            <Input
              type="file"
              input={{
                onChange: handleImage,
                multiple: true,
              }}
            />
            <Image image="v01_blur-14.png" />
            <p className="text">Upuść pliki tutaj</p>
            <span className="or">LUB</span>
            <Button text="Prześlij pliki" secondary />
          </div>
        </Column>
        <Column xs={12} md={4} className="loaded-files-wrapper">
          <Row>
            <>
              {images.length > 0 &&
                images.map((image, index) => {
                  const { previewUrl } = image;
                  return (
                    <Column
                      xs={6}
                      md={12}
                      className={clsx('img-wrapper', { odd: index % 2 === 0, even: index % 2 !== 0 })}
                      key={previewUrl}
                    >
                      <img onClick={() => dispatch(removePreviewImage(previewUrl))} key={previewUrl} src={previewUrl} />
                    </Column>
                  );
                })}
              <Column xs={6} className="mobile-input">
                <div className="content-wrapper">
                  <Icon icon="plus" />
                  <p className="title">Dodaj zdjęcie</p>
                </div>
                <Input
                  type="file"
                  input={{
                    onChange: handleImage,
                    multiple: true,
                  }}
                />
              </Column>
            </>
          </Row>
        </Column>
      </Row>
    </Column>
  );
};

export default AddMultiplePhoto;
