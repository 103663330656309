import React, { useEffect, useState } from 'react';
import { rankingTableSchema, FieldType, TableResponse } from 'topdekarze-tables';
import { RankingTableRes, EventTableRes, UserRole } from 'topdekarze-types';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import { Main, Row, Container, Column, ButtonsContainer } from '../../../../components/Layout';
import TableWrapper from '../../../../components/Common/TableWrapper';
import ApiTable from '../../../../components/Common/ApiTable';
import { Button, Spinner } from '../../../../components/Common';
import DashboardBackground from '../../../../components/Common/DashboardBackground';
import useHeader from '../../../../hooks/useHeader';
import EntrySection from '../../Start/EntrySection';
import './MainRating.scss';
import ApiService from '../../../../services/api-service';
import { RootState } from '../../../../reducers';
import UserLevel from '../UserLevel';
import { refreshApiTable } from '../../../../actions/api-table';

const MainRating = () => {
  useHeader('');
  const dispatch = useDispatch();
  const [ratings, setRatings] = useState<{ id: string; title: string }[]>([]);
  const [rating, setRating] = useState<string>('');
  const user = useSelector((state: RootState) => state.user.details);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    await ApiService.callFetch('GET', 'event/list', (data: EventTableRes[]) => {
      let newRatings: { id: string; title: string }[] = [];
      if (user!.role === UserRole.User || user!.role === UserRole.Admin) {
        newRatings = [...newRatings, { id: 'ranking/main-ranking', title: 'Ranking główny' }];
      }
      if (user!.role === UserRole.SuperUser || user!.role === UserRole.Admin) {
        newRatings = [...newRatings, { id: 'ranking/superuser-ranking', title: 'Ranking DHT' }];
      }
      newRatings = [...newRatings, ...data.map((e) => ({ id: `ranking/special-ranking/${e.id}`, title: e.title }))];
      setRatings(newRatings);
      setRating(newRatings[0].id);
    });
  };

  const updateRating = (id: string) => {
    setRating(id);
    dispatch(refreshApiTable());
  };

  const scheme = new InteractiveTableSchema(rankingTableSchema)
    .removeFields('id', 'firstName', 'lastName', 'points')
    .addFieldAtTheBeginning({
      field: 'participant',
      name: 'Uczestnik',
      type: FieldType.Custom,
      customRender: (value, field, row: RankingTableRes) => (
        <div className={clsx('rating-user')}>
          <img className="rating-avatar" src={`${ApiService.url}user/avatar/${row.id}`} alt="" />
          <strong className="rating-user-name">{`${row.firstName} ${row.lastName}`}</strong>
        </div>
      ),
    })
    .addFieldAtTheBeginning({
      field: 'place',
      name: 'Miejsce',
      type: FieldType.Custom,
      customRender: (value, field, row: RankingTableRes) => <div className={clsx('rating-place')}>{row.place}</div>,
    })
    .addFieldAtTheEnd({
      field: 'points',
      name: 'Punkty',
      type: FieldType.Custom,
      customRender: (value, field, row: RankingTableRes) => (
        <div className={clsx('rating-points')}>{`${row.points} pkt.`}</div>
      ),
    });

  if (!rating) return <Spinner />;

  return (
    <Main className="start-view">
      <DashboardBackground />
      <EntrySection noStats />
      {user!.role === UserRole.User && <UserLevel />}
      <Container>
        <ButtonsContainer className="available-ratings">
          {ratings.map((r) => (
            <Button primary disabled={r.id === rating} text={r.title} click={() => updateRating(r.id)} />
          ))}
        </ButtonsContainer>
      </Container>
      <TableWrapper>
        <ApiTable
          scheme={scheme}
          apiEndpointSubUrl={rating}
          noSearch
          highlight={(row: RankingTableRes) => row.id === user!.id}
        />
      </TableWrapper>
    </Main>
  );
};

export default MainRating;
