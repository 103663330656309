import React, { FC } from 'react';
import { Container, Main, Row } from '../../../../components/Layout';
import useAddArticle from '../../../../hooks/articles/useAddArticlee';
import AddArticlesForm from '../../../../components/Form/AddArticlesForm';

const AddArticle: FC<null> = () => {
  const { handleEditorChange, content, returnFormSettings } = useAddArticle();

  return (
    <Main>
      <Container>
        <Row>
          <AddArticlesForm
            formSettings={returnFormSettings}
            content={content || null}
            handleEditorChange={handleEditorChange}
          />
        </Row>
      </Container>
    </Main>
  );
};

export default AddArticle;
