import React from 'react';
import { Container, Main, Row } from '../../../../components/Layout';
import Card from '../../../../components/Common/Card';
import { Spinner } from '../../../../components/Common';
import useEventList from '../../../../hooks/events/useEventList';
import EntryText from '../../../../components/Common/EntryText';
import useHeader from '../../../../hooks/useHeader';

const SpecialActionsList = (): JSX.Element => {
  useHeader('Zadania specjalne', '/dashboard');
  const { events, isLoading } = useEventList();

  if (isLoading) return <Spinner />;

  return (
    <Main>
      <Container>
        <EntryText>
          W tym miejscu znajdziesz informacje o akcjach specjalnych, za których realizację możesz zyskać ekstra punkty.
          Dodatkowe zadania to także szansa na zdobycie atrakcyjnych nagród rzeczowych!
        </EntryText>
        <Row as="ul">
          {events.map((event, index) => (
            <Card
              key={event.id}
              className="action"
              title={event.title}
              isFirst={false}
              imgUrl={`event/event-image/${event.id}`}
              to={`/dashboard/special-tasks/${event.id}`}
              time={event?.title}
              specialTask
            />
          ))}
        </Row>
      </Container>
    </Main>
  );
};

export default SpecialActionsList;
