import React from 'react';
import { InvitedUserTableRes } from 'topdekarze-types';
import { FieldType, invitedUserSchema } from 'topdekarze-tables';
import { useDispatch } from 'react-redux';
import ApiService from '../../../services/api-service';
import { Main, Container, ButtonsContainer } from '../../../components/Layout';
import ApiTable from '../../../components/Common/ApiTable';
import { InteractiveTableSchema } from '../../../utils/table-schema-utils';
import { Button } from '../../../components/Common';
import { __ } from '../../../helpers/i18n';
import TableWrapper from '../../../components/Common/TableWrapper';
import useHeader from '../../../hooks/useHeader';
import { refreshApiTable } from '../../../actions/api-table';
import { successNotification } from '../../../actions/notifications';
import './Invitations.scss';

interface Props {
  fetchAllInvitations: (url: string, method?: string) => void;
  deleteInvitation: (email: string) => void;
  successDeleteNotification: (text: string) => void;
}

const Invitations = ({ fetchAllInvitations, deleteInvitation, successDeleteNotification }: Props) => {
  useHeader('Zaproszenia', '/dashboard/invite-user');
  const dispatch = useDispatch();

  const sendInvitation = async (row: InvitedUserTableRes) => {
    await ApiService.callFetch(
      'POST',
      `user/send-invitation/${row.id}`,
      () => {
        dispatch(refreshApiTable());
        dispatch(successNotification(`${__('application.invitationSent')}`));
      },
      null,
    );
  };

  const scheme = new InteractiveTableSchema(invitedUserSchema).removeFields('id').addFieldAtTheEnd({
    name: 'Akcje',
    field: 'actions',
    type: FieldType.Custom,
    customRender: (value, field, row: InvitedUserTableRes) => renderOtherActionsButtons(row),
    mobile: true,
  });

  const renderOtherActionsButtons = (row: InvitedUserTableRes) => (
    <Button primary small text={__('application.sendAgain')} click={() => sendInvitation(row)} />
  );

  return (
    <Main className="invitations-table">
      <Container>
        <ButtonsContainer>
          <Button small to="/dashboard/invite-user" text="Wyślij zaproszenie" />
        </ButtonsContainer>
      </Container>
      <TableWrapper>
        <ApiTable
          scheme={scheme}
          apiEndpointSubUrl="user/invited-by-me-table"
          dehighlight={(row: InvitedUserTableRes) => row.isRegistered}
        />
      </TableWrapper>
    </Main>
  );
};

export default Invitations;
