import React, { useState, useEffect } from 'react';
import { ArticleListRes, ArticleRes } from 'topdekarze-types';
import ApiService from '../services/api-service';

interface UseHomeNews {
  article: any;
  isLoading: boolean;
  isMobile: boolean | undefined;
}

const ArticleLimit = 2;

const useHomeNews = (): UseHomeNews => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState<undefined | boolean>();
  const [article, setArticle] = useState<any>();

  const getLastArticles = (index: number) => {
    ApiService.callFetch(
      'GET',
      `article/list/all/${index}`,
      (data: ArticleRes[]) => {
        setIsLoading(false);
        setArticle(data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const isMobileFn = () => {
    const media = window.matchMedia('(min-width: 991px)');
    if (media.matches) {
      setIsMobile(false);
    } else setIsMobile(true);
  };

  useEffect(() => {
    getLastArticles(ArticleLimit);
    isMobileFn();
  }, []);

  return { article, isLoading, isMobile };
};

export default useHomeNews;
