import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import Start from './Start';
import MyProfile from './MyProfile';
import MyUsers from './MyUsers';
import VerifyUsers from './VerifyUsers';
import Users from './Users';
import InviteUser from './InviteUser';
import Invitations from './Invitations';
import UsersImporting from './UsersImporting';
import { DashboardNav } from '../../types/navs';
import MobileNav from '../../components/Common/MobileNav';
import Footer from '../../components/Common/Footer';
import DesktopNav from '../../components/Common/DesktopNav';
import AddTransaction from './Transactions/AddTransaction';
import AllTransactions from './Transactions/AllTransactions';
import Transaction from './Transactions/Transaction';
import RouteSuperUser from '../../components/Routes/RouteSuperUser';
import RouteAdmin from '../../components/Routes/RouteAdmin';
import MainRating from './Rating/MainRating';
import Quizzes from './Quizzes';
import SpecialActions from './SpecialActions';
import Articles from './Articles';
import RouteAuthorized from '../../components/Routes/RouteAuthorized';
import DHTHotChallenge from './DHTHotChallenge';
import MainPrize from './MainPrize';
import { CompetitionRules } from '..';
import UsersPreview from './Users/Preview';

const nav: DashboardNav[] = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    iconName: 'home',
  },
  {
    text: 'Mój profil',
    path: '/dashboard/my-profile',
    iconName: 'user',
  },
  {
    text: 'Info',
    path: '/dashboard/competition-rules',
    iconName: 'info',
  },
];

const Dashboard: FC = () => (
  <>
    <DesktopNav menuItems={nav} />
    <Switch>
      <Route exact path="/dashboard" component={Start} />
      <Route exact path="/dashboard/my-profile" component={MyProfile} />
      <Route exact path="/dashboard/my-users" component={MyUsers} />
      <Route exact path="/dashboard/verify-users" component={VerifyUsers} />
      <Route exact path="/dashboard/transactions/add" component={AddTransaction} />
      <Route exact path="/dashboard/invite-user" component={InviteUser} />
      <RouteSuperUser exact path="/dashboard/transactions/list" component={AllTransactions} />
      <RouteSuperUser path="/dashboard/transactions/list/:id" component={Transaction} />
      <Route exact path="/dashboard/invitations" component={Invitations} />
      <RouteAuthorized path="/dashboard/articles/" component={Articles} />
      <RouteAdmin exact path="/dashboard/import-users" component={UsersImporting} />
      <Route exact path="/dashboard/users" component={Users} />
      <RouteAdmin path="/dashboard/users/preview/:userId" component={UsersPreview} />
      <Route exact path="/dashboard/rating/main-rating" component={MainRating} />
      <RouteAuthorized path="/dashboard/quizzes" component={Quizzes} />
      <Route path="/dashboard/special-tasks/" component={SpecialActions} />
      <RouteSuperUser exact path="/dashboard/hot-challenge" component={DHTHotChallenge} />
      <Route exact path="/dashboard/main-prize" component={MainPrize} />
      <Route exact path="/dashboard/competition-rules" component={CompetitionRules} />
    </Switch>
    <Footer />
    <MobileNav menuItems={nav} />
  </>
);

export default Dashboard;
