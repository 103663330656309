import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../../../actions/user';

interface Props {
  children: React.ReactNode;
}

const AppWrapper = ({ children }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  return <>{children}</>;
};

export default AppWrapper;
