import React from 'react';
import { InvitedUserTableRes, ArticleTableRes } from 'topdekarze-types';
import { FieldType, invitedUserSchema, articleTableSchema } from 'topdekarze-tables';
import { useDispatch } from 'react-redux';
import { Main, Container, ButtonsContainer } from '../../../../components/Layout';
import ApiTable from '../../../../components/Common/ApiTable';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import TableWrapper from '../../../../components/Common/TableWrapper';
import useHeader from '../../../../hooks/useHeader';
import { showModal } from '../../../../actions/modal';
import Confirmation from '../../../../components/Modals/Confirmation';
import ApiService from '../../../../services/api-service';
import { refreshApiTable } from '../../../../actions/api-table';
import { successNotification } from '../../../../actions/notifications';
import { hideModal } from '../../../../actions/modal';
import { history } from '../../../../App';
import { Button } from '../../../../components/Common';

const Manage = () => {
  useHeader('Lista artykułów', '/dashboard/');
  const dispatch = useDispatch();

  const deleteArticle = async (article: ArticleTableRes) => {
    await ApiService.callFetch(
      'DELETE',
      `article/delete/${article.id}`,
      () => {
        dispatch(refreshApiTable());
        dispatch(successNotification(`Poprawnie usunięto artykuł`));
        dispatch(hideModal());
      },
      null,
    );
  };

  const confirmDelete = (article: ArticleTableRes) => {
    dispatch(
      showModal(
        <Confirmation
          text={`Czy na pewno chcesz usunąć artykuł ${article.title}?`}
          confirmCallback={() => {
            deleteArticle(article);
          }}
        />,
      ),
    );
  };

  const goToArticle = (article: ArticleTableRes) => {
    history.push(`/dashboard/articles/view/${article.id}`);
  };

  const scheme = new InteractiveTableSchema(articleTableSchema).removeFields('id').addFieldAtTheEnd({
    name: 'Akcje',
    field: 'actions',
    buttons: [
      { name: 'Usuń', type: 'danger', click: confirmDelete },
      { name: 'Zobacz/Edytuj', type: 'primary', click: goToArticle },
    ],
  });

  return (
    <Main>
      <Container>
        <ButtonsContainer>
          <Button small to="/dashboard/articles/add" text="Nowy artykuł" />
        </ButtonsContainer>
      </Container>
      <TableWrapper>
        <ApiTable scheme={scheme} apiEndpointSubUrl="article/table/all" />
      </TableWrapper>
    </Main>
  );
};

export default Manage;
