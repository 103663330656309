import React, { FC } from 'react';
import { UserRole } from 'topdekarze-types';
import { useSelector } from 'react-redux';
import { Container } from '../../Layout';
import UserActionBox from '../UserActionBox';
import { RootState } from '../../../reducers';
import './UserActions.scss';

const userActionsBox = [
  {
    id: 0,
    text: 'weryfikacja zgłoszonych dachów',
    path: '/dashboard/transactions/list',
    bgName: 'magnifier-bg',
    role: [UserRole.SuperUser, UserRole.Admin],
    bgColor: '#DA251C',
  },
  {
    id: 1,
    text: 'zaproszenie nowych dekarzy',
    path: '/dashboard/invite-user',
    bgName: 'time-action-bg',
    role: [UserRole.SuperUser],
    bgColor: '#012633',
  },
  {
    id: 2,
    text: 'weryfikacja dekarzy z polecenia i rejestracji ze strony www',
    path: '/dashboard/verify-users',
    bgName: 'quiz-bg',
    role: [UserRole.SuperUser],
    bgColor: '#012633',
  },
  {
    id: 3,
    text: 'Moi Dekarze',
    path: '/dashboard/my-users',
    bgName: 'time-action-bg',
    role: [UserRole.SuperUser],
    bgColor: '#009dd4',
  },
  {
    id: 4,
    text: 'application.addTransaction',
    path: '/dashboard/transactions/add',
    bgName: 'transaction',
    role: [UserRole.User],
    bgColor: '#DA251C',
  },
  {
    id: 5,
    text: 'Zadania specjalne',
    path: '/dashboard/special-tasks',
    bgName: 'time-action-bg',
    role: [UserRole.User, UserRole.SuperUser, UserRole.Admin],
    bgColor: '#012633',
  },
  {
    id: 6,
    text: 'Quizy',
    path: '/dashboard/quizzes',
    bgName: 'quiz-bg',
    role: [UserRole.User, UserRole.Admin],
    bgColor: '#005876',
  },
  {
    id: 7,
    text: 'Baza wiedzy',
    path: '/dashboard/articles',
    bgName: 'bulb',
    role: [UserRole.User, UserRole.Admin],
    bgColor: '#009dd4',
  },
  // {
  //   id: 8,
  //   text: 'Dodaj artykuł',
  //   path: '/dashboard/articles/add',
  //   bgName: 'time-action-bg',
  //   role: [UserRole.Admin],
  //   bgColor: '#DA215C',
  // },
  {
    id: 9,
    text: 'application.users',
    path: '/dashboard/users',
    bgName: 'quiz-bg',
    role: [UserRole.Admin],
    bgColor: '#009dd4',
  },

  {
    id: 10,
    text: 'application.importCSV',
    path: '/dashboard/import-users',
    bgName: 'time-action-bg',
    role: [UserRole.Admin],
    bgColor: '#bb9c71',
  },
  {
    id: 11,
    text: 'DHT hot challenge',
    path: '/dashboard/hot-challenge',
    bgName: 'quiz-bg',
    role: [UserRole.SuperUser],
    bgColor: '#009dd4',
  },
];

const UserActions: FC = () => {
  const user = useSelector((state: RootState) => state.user.details);

  return (
    <section className="user-details">
      <Container>
        <div className="user-actions">
          {userActionsBox.map((box) => {
            const { path, bgName, text, id, role, bgColor } = box;
            if (user?.firstName && role?.includes(user.role)) {
              return (
                <UserActionBox
                  key={id}
                  bgIcon={bgName}
                  backgroundColor={bgColor || '#31D4A3'}
                  text={text}
                  path={path}
                />
              );
            }
            return null;
          })}
        </div>
      </Container>
    </section>
  );
};

export default UserActions;
